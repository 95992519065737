export type DebounceClear = () => void;
export type Debounce = (callback: () => void, ms: number) => DebounceClear;

export const debounce: Debounce = (callback, ms) => {
  let timeout: NodeJS.Timeout | null = setTimeout(() => {
    callback();

    timeout = null;
  }, ms);

  return () => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
  };
};

export const createDebounceGroup = (): Debounce => {
  let lastDebounceClear: DebounceClear | null = null;

  return (callback, ms) => {
    if (lastDebounceClear !== null) {
      lastDebounceClear();
    }

    lastDebounceClear = debounce(callback, ms);

    return lastDebounceClear;
  };
};