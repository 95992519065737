import { css } from '@emotion/css';
import React from 'react';
import { EventStatuses, StatusEvent, getStatusBarTickCount, intervalsFunctions, IntervalUnit } from '@statusgate/common';
import { Size, sizesParams, barDisplayMargin, barPaddingOffset, tickArrowHeight, tickArrowMarginTop, toolTipDefaultWidth } from '../StatusBarSizes';
import { BarTickData, BarDisplayInfos } from '../StatusBarTypes';
import { panelClass } from '../../../style/panelClass';
import StatusBar from '../StatusBar';

const tooltipClass = css`
  position: absolute;
  z-index: 50;
`;

const getTickWidth = (size: Size) => {
  return sizesParams[size].tickWidth + 2;
};

const getTooltipPositionStyle = (
  barMin: Date,
  barMax: Date,
  barInterval: number,
  barIntervalUnit: IntervalUnit,
  barSize: Size,
  index: number,
  target: HTMLDivElement,
  subBar?: {
    min: Date,
    max: Date,
    interval: number,
    intervalUnit: IntervalUnit,
    size: Size,
  }
) => {

  const barTickCount = getStatusBarTickCount(barMin, barMax, barInterval, barIntervalUnit);
  const barTickSize = getTickWidth(barSize);
  const barTicksWidth = barTickCount * barTickSize;

  let subBarTicksWidth = toolTipDefaultWidth;

  if (subBar !== undefined) {
    const subBarTickCount = getStatusBarTickCount(subBar.min, subBar.max, subBar.interval, subBar.intervalUnit);
    const subBarTickSize = getTickWidth(subBar.size);
    subBarTicksWidth = subBarTickCount * subBarTickSize;
  }

  const barFullSize = barTicksWidth + 2 * (barPaddingOffset - barDisplayMargin);
  const tooltipFullSize = subBarTicksWidth + 2 * (barPaddingOffset + 1);

  const freeSpace = Math.max(barFullSize - tooltipFullSize, 0);

  const positionPercent = index / (barTickCount - 1);

  const position = freeSpace * positionPercent + (barPaddingOffset - barDisplayMargin) - 2;

  return {
    left: `${position}px`,
    top: `${target.offsetTop + target.offsetHeight + tickArrowHeight + tickArrowMarginTop - 1}px`,
  };
};

export interface StatusBarTooltipProps {
  componentName: string;
  size: Size;
  events: StatusEvent[];
  currentStatus: EventStatuses;
  currentStatusDate: Date | null | undefined;
  min: Date;
  max: Date;
  tickInterval: number;
  intervalUnit: IntervalUnit;
  subBar?: BarDisplayInfos;
  tooltipTick: BarTickData;
  setHovered: (hovered: boolean) => void;
  onClose?: () => void;
}

const StatusBarTooltip: React.FC<StatusBarTooltipProps> = ({ componentName, size, events, currentStatus, currentStatusDate, min, max, tickInterval, intervalUnit, subBar, tooltipTick, setHovered, onClose }) => {

  return (
    <div
      className={tooltipClass}
      style={getTooltipPositionStyle(
        min,
        max,
        tickInterval,
        intervalUnit,
        size,
        tooltipTick.index,
        tooltipTick.target,
        subBar === undefined || subBar.tickInterval === undefined ? undefined : {
          min: tooltipTick.tick.startDate,
          max: tooltipTick.tick.stopDate,
          interval: subBar.tickInterval,
          intervalUnit: subBar.intervalUnit,
          size: 'small',
        },
      )}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <div className={panelClass}>
      {
        subBar && 
          <StatusBar
            title={intervalUnit === 'days'
              ? intervalsFunctions[intervalUnit].display(tooltipTick.tick.startDate, 'min')
              : undefined
            }
            componentName={componentName}
            events={events}
            currentStatus={currentStatus}
            currentStatusDate={currentStatusDate}
            min={tooltipTick.tick.startDate}
            max={tooltipTick.tick.stopDate}
            display={subBar}
            onClose={onClose}
          />
      }
      </div>
    </div>
  );
};

export default StatusBarTooltip;
