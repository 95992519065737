import { intervalsFunctions, IntervalUnit } from './defines';

export const getStatusBarTickCount = (min: Date, max: Date, interval: number, intervalUnit: IntervalUnit) => {
  const get = intervalsFunctions[intervalUnit].get;
  const set = intervalsFunctions[intervalUnit].set;

  let tickCount = 0;

  let currentDate = min;

  while (currentDate.getTime() < max.getTime()) {
    ++tickCount;

    currentDate = new Date(currentDate);
    set.call(currentDate, get.call(currentDate) + interval);
  }

  return tickCount;
};
