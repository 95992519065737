import { css } from "@emotion/css";

export type Size = 'big' | 'normal' | 'small';

export const barPaddingOffset = 10;
export const barDisplayMargin = 4;

export const tickArrowHeight = 10;
export const tickArrowHalfWidth = 7;
export const tickArrowMarginTop = 3;

export const toolTipDefaultWidth = 200;

export interface SizeParams {
  closeIconSize: number;
  tickWidth: number;
  tickHeight: number;
  titleClass: string;
  subtitleClass: string;
  legendClass: string;
}

export const sizesParams: Record<Size, SizeParams> = {
  big: {
    closeIconSize: 24,
    tickWidth: 8,
    tickHeight: 45,
    titleClass: css`
      margin-bottom: 0.5em;
      font-size: 1.1em;
    `,
    subtitleClass: css`
      margin-top: -0.5em;
      margin-bottom: 0.5em;
      font-size: 0.8em;
      color: #999999;
    `,
    legendClass: css`
      padding-top: 12px;
      font-size: 1em;
    `,
  },
  normal: {
    closeIconSize: 20,
    tickWidth: 7,
    tickHeight: 40,
    titleClass: css`
      margin-bottom: 0.5em;
      font-size: 1em;
    `,
    subtitleClass: css`
      margin-top: -0.5em;
      margin-bottom: 0.5em;
      font-size: 0.75em;
      color: #999999;
    `,
    legendClass: css`
      padding-top: 10px;
      font-size: 0.875em;
    `,
  },
  small: {
    closeIconSize: 16,
    tickWidth: 4,
    tickHeight: 25,
    titleClass: css`
      margin-bottom: 0.5em;
      font-size: 0.875em;
    `,
    subtitleClass: css`
      margin-top: -0.5em;
      margin-bottom: 0.5em;
      font-size: 0.7em;
      color: #999999;
    `,
    legendClass: css`
      padding-top: 5px;
      font-size: 0.8em;
    `,
  },
};
