import { css } from '@emotion/css';
import React from 'react';
import { StatusEvent, StatusNativeEvent, statusDisplay, getEventsRange, getIssuesList, Issue } from '@statusgate/common';
import { useMemo } from 'react';
import { groupBy, mapObj } from '@bold/generics-helper';


const issueListBoxClass = css`
  background-color: #eeeeee;
  padding: 0.5em;
  margin: 0.5em 0;
  font-size: 0.8em;

  .componentName {
    font-weight: bold;
  }
`;

const eventListLinkClass = css`
  text-decoration: underline;
  cursor: pointer;
  color: black;

  &:hover {
    text-decoration: none;
  }
`;

export interface StatusBarEventListProps {
  componentName: string;
  events: StatusEvent[];
  min: Date;
  max: Date;
}

const StatusBarEventList: React.FC<StatusBarEventListProps> = ({ componentName, events, min, max }) => {

  const scopeEvents = useMemo(() => {
    const { initialStatusEventIndex, scopeEvents } = getEventsRange(events, min, max);

    if (initialStatusEventIndex === -1) {
      return scopeEvents;
    }

    return [events[initialStatusEventIndex], ...scopeEvents];

  }, [events, min, max]);

  const issuesLists = useMemo(() => {

    const nativeEvents: StatusNativeEvent[] = [];

    scopeEvents.forEach(scopeEvent => {
      nativeEvents.push(...scopeEvent.nativeEvents);
    });

    const nativeEventsByComponent = groupBy(nativeEvents, (nativeEvent) => nativeEvent.entityName);

    return mapObj(nativeEventsByComponent, nativeEvents => getIssuesList(nativeEvents));

  }, [scopeEvents]);

  const getDateRangeDisplay = (issue: Issue) => {
    const now = new Date();
    let startDate = min;
    let stopDate = now.getTime() < max.getTime() ? now : max;

    if (issue.startDate.getTime() > startDate.getTime()) {
      startDate = issue.startDate;
    }

    if (issue.stopDate !== undefined) {
      if (issue.stopDate.getTime() < stopDate.getTime()) {
        stopDate = issue.stopDate;
      }
    }

    return `${startDate.toLocaleTimeString()} - ${stopDate.toLocaleTimeString()}`;
  };

  return <>
    {
      Object.entries(issuesLists).map(([issuesComponentName, issueList]) => <React.Fragment key={issuesComponentName}>
        {
          issueList.length > 0 && <div className={issueListBoxClass}>
            {
              issuesComponentName !== componentName && (
                <div className="componentName">{issuesComponentName}</div>
              )
            }
            {
              [...issueList].reverse().map(issue => (
                <div key={issue.startDate.getTime()}>
                  {getDateRangeDisplay(issue)}
                  {' '}
                  <a className={eventListLinkClass} href={`/thread/${issue.threadId}`} target="_blank">{statusDisplay[issue.status]}</a>
                </div>
              ))
            }
          </div>
        }
      </React.Fragment>)
    }
  </>;
};

export default StatusBarEventList;
