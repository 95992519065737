import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from "./config.json";
import schema from "./bold/schema.json";
import models from "./bold/models.json";
import { BrowserRouter } from "react-router-dom";
import { createClient, BoldProvider } from '@bold/client';
import { getToken } from './token';

const client = createClient({
  serverUrl: config.SERVER_URL,
  schema,
  models,
  getToken,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <BoldProvider client={client}>
        <App />
      </BoldProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
