import { indexBy } from "@bold/generics-helper";

export const getArrayDiff = <TO, TN>(oldArray: TO[], oldArrayKeyGetter: (item: TO) => string, newArray: TN[], newArrayKeyGetter: (item: TN) => string) => {
  const mappedOldArray = indexBy(oldArray, oldArrayKeyGetter);
  const mappedNewArray = indexBy(newArray, newArrayKeyGetter);

  const toRemove = oldArray.filter(item => !(oldArrayKeyGetter(item) in mappedNewArray));
  const toAdd = newArray.filter(item => !(newArrayKeyGetter(item) in mappedOldArray));

  return {
    toRemove,
    toAdd,
  };
};
