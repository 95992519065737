import React, { useState } from 'react';
import BasicLayout from '@guy/basic-layout';
import { CollectionsProvider } from '@guy/collections';
import { CollectionsContextType } from '@guy/collections-type';
import { Flow, Item } from '@guy/flex-flow';
import { css, cx } from '@emotion/css';

import { DrawerMenu, DrawerItem } from '@guy/drawer-elements';
import MuiDrawerCollection from '@guy/mui-drawer-collection';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const collections: CollectionsContextType = {
  drawer: MuiDrawerCollection,
};

const drawerShadowClass = css`
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.15);
`;

const navbarClass = css`
  // box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 0.5em;
`;

const contentClass = css`
  // box-shadow: inset 0px 13px 5px -10px rgba(0, 0, 0, 0.15);
`;

interface LayoutProps {
  children: React.ReactNode;
  drawerContent?: React.ReactNode;
  navBarRight?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, drawerContent, navBarRight }) => {
  const [open, setOpen] = useState(true);
  const toggleOpen = () => setOpen(!open);

  return (
    <CollectionsProvider collections={collections}>
      <BasicLayout>
        {
          drawerContent ? (
            <BasicLayout.Drawer
              position="left"
              open={open}
              setOpen={setOpen}
              width='250px'
              className={cx(open && drawerShadowClass)}
            >
              <Flow direction="col" maximize>
                <Item type="spreadable" option="scrollable">
                  {drawerContent}
                </Item>
                <Item type="fitted">
                  <DrawerMenu>
                    <DrawerItem text="Disconnect" url="/logout" />
                  </DrawerMenu>
                </Item>
              </Flow>
              
            </BasicLayout.Drawer>
          ) : undefined
        }
        
        <BasicLayout.Navbar sticky={true} className={navbarClass}>
          <Flow direction="row">
            <Item type="fitted">
              <IconButton color="default" onClick={toggleOpen}>
                <MenuIcon />
              </IconButton>
            </Item>
            <Item type="spreadable" />
            <Item type="fitted">
              {navBarRight}
            </Item>
          </Flow>
        </BasicLayout.Navbar>
        <BasicLayout.Content className={contentClass} restrict>
          {children}
        </BasicLayout.Content>
      </BasicLayout>
    </CollectionsProvider>
  );

};

export default Layout;
