import { useFindEventsQuery, useFindEventsWithThreadItemsQuery } from '../bold/gql';
import { getBoundaryDates } from '../helpers/getBoundaryDates';
import { EventStatuses } from '@statusgate/common';
import Luq from '@luq/core';
import { FindEntityQuery, FindEventsQuery, FindEventsQueryVariables, FindEventsWithThreadItemsQuery } from '../bold/gqlTypes';
import { ApolloError, QueryHookOptions, QueryResult } from '@apollo/client';
import { StatusEvent, OneOf } from '@statusgate/common';
import { useEntityDependencies } from './useEntityDependencies';
import { useMemo } from 'react';

export type UseQuery<TData, TVariables> = (options: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>;

const useEvents = <DataType extends FindEventsQuery | FindEventsWithThreadItemsQuery>(entityIds: string[], handler: UseQuery<DataType, FindEventsQueryVariables>, skip: boolean = false): {
  loading: boolean,
  error: ApolloError | undefined,
  data: DataType['findEventList'] | undefined;
} => {
  const result = handler({
    variables: {
      eventListFilter: Luq(
        <Luq.And>
          <Luq.In field="entityId" values={entityIds} />
          <Luq.Neq field="old" value={true} />
        </Luq.And>
      ) as string,
      eventListOrder: Luq(
        <Luq.Order>
          <Luq.Asc field="date" />
        </Luq.Order>
      ) as string,
    },
    skip,
  });

  const { data, error, loading } = result;

  return {
    loading,
    error,
    data: data === undefined ? undefined : data.findEventList,
  };
};

// export const useBaseEvents = (entityId: string) => {
//   const { data, error, loading } = useEvents([entityId], useFindEventsQuery);

//   const events: StatusEvent[] = (data ?? []).map(event => ({
//     date: event.date,
//     status: event.status as unknown as EventStatuses,
//     nativeEvents: [],
//   }));

//   return {
//     events,
//     error,
//     loading,
//   };
// };

export const useEventsWithThreadItems = (entity: OneOf<FindEntityQuery['findEntity']>, skip: boolean = false) => {
  const { data: dependenciesData, error: dependenciesError, loading: dependenciesLoading } = useEntityDependencies(entity, true, skip);

  const entityIds = [entity.id, ...dependenciesData];

  const { data: eventsData, error: eventsError, loading: eventsLoading } = useEvents(entityIds, useFindEventsWithThreadItemsQuery, skip || dependenciesLoading || dependenciesError !== undefined || dependenciesData === undefined);

  return useMemo(() => {
    if (dependenciesLoading || eventsLoading) {
      return {
        events: null,
        error: undefined,
        loading: true,
      };
    }
  
    if (dependenciesError !== undefined || dependenciesData === undefined) {
        return {
        events: null,
        error: dependenciesError,
        loading: false,
      };
    }
  
    if (eventsError !== undefined || eventsData === undefined) {
      return {
        events: null,
        error: eventsError,
        loading: false,
      };
    }
  
    const threadItemEventsMap: Map<string, OneOf<FindEventsWithThreadItemsQuery['findEventList']>> = new Map();
  
    const clonedEventsData = [...eventsData];

    clonedEventsData.sort((a, b): number => {
      const timeA = a.date.getTime();
      const timeB = b.date.getTime();
  
      if (timeA !== timeB) {
        return timeA - timeB;
      }
  
      if (a.entity.id === entity.id) {
        if (b.entity.id === entity.id) {
          return 0;
        }
        else {
          return 1;
        }
      }
      else {
        if (b.entity.id === entity.id) {
          return -1;
        }
        else {
          return 0;
        }
      }
    });
  
    const events: StatusEvent[] = [];
  
    for (const eventData of clonedEventsData) {
  
      if (eventData.threadItem !== undefined && eventData.threadItem !== null) {
        threadItemEventsMap.set(eventData.entity.id, eventData);
      }
  
      if (eventData.entity.id === entity.id) {
        events.push({
          date: eventData.date,
          status: eventData.status as unknown as EventStatuses,
          nativeEvents: Array.from(threadItemEventsMap.values()).map(threadEventData => {
  
            const threadItem = threadEventData.threadItem as NonNullable<OneOf<FindEventsWithThreadItemsQuery['findEventList']>['threadItem']>;
  
            return {
              threadId: threadItem.threadId,
              entityName: threadEventData.entity.displayName ?? threadEventData.entity.identifier,
              comment: threadItem.comment ?? null,
              date: threadEventData.date,
              status: threadEventData.status as unknown as EventStatuses,
            };
          }),
        });
      }
    }
    
    return {
      events,
      error: undefined,
      loading: false,
    };
  }, [
    dependenciesData,
    dependenciesError,
    dependenciesLoading,
    entity.id,
    eventsData,
    eventsError,
    eventsLoading,
  ]);
};