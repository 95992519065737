import { EventStatuses } from '../types';
import { StatusEvent } from './types';
import { statusAll, statusUp } from './defines';
import { getEventsRange } from './getEventsRange';

export const getUptime = (events: StatusEvent[], min: Date, max: Date): number | null => {
  let timeUp = 0;
  let timeAll = 0;

  const { initialStatusEventIndex, scopeEvents } = getEventsRange(events, min, max);

  let currentStatusStartDate: Date = min;
  let currentStatus: EventStatuses = initialStatusEventIndex === -1 ? EventStatuses.Undefined : events[initialStatusEventIndex].status;

  const fillTimes = (to: Date) => {

    const eventTime = to.getTime() - currentStatusStartDate.getTime();

    if (statusAll[currentStatus]) {
      timeAll += eventTime;
    }

    if (statusUp[currentStatus]) {
      timeUp += eventTime;
    }
  };

  for (const event of scopeEvents) {
    let startTime = event.date;
    if (startTime.getTime() < min.getTime()) {
      startTime = min;
    }

    fillTimes(startTime);

    currentStatusStartDate = startTime;
    currentStatus = event.status;
  }

  fillTimes(max);

  return timeAll > 0 ? timeUp / timeAll : null;
};
