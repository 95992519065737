import React, { useEffect, useState } from 'react';
import { useFindEntityQuery } from '../../bold/gql';
import Luq from '@luq/core';
import List, { barDisplayBig } from './List';
import InfiniteScroll from 'react-infinite-scroller';
import { EventStatuses } from '@statusgate/common';
import { Centered, Flow, Item } from '@guy/flex-flow';
import { CircularProgress } from '@mui/material';

const FETCHLIMIT = 10;

const useInfiniteQuery = (filter?: string) => {

  const [offset, setOffset] = useState(0);

  const { data, error, loading, fetchMore } = useFindEntityQuery({
    variables: {
      filter,
      order: Luq(<Luq.Order>
        <Luq.Asc field="identifier" />
      </Luq.Order>),
      limit: FETCHLIMIT,
    },
  });

  useEffect(() => {
    setOffset(0);
  }, [filter]);

  useEffect(() => {
    if (offset > 0) {
      fetchMore({
        variables: {
          offset,
        }
      });
    }
  }, [offset, fetchMore]);

  const loadMore = () => {
    if (!loading) {
      setOffset(offset + FETCHLIMIT);
    }
  };

  const hasMore = data !== undefined && data.findEntity.length >= offset + FETCHLIMIT;

  return {
    loading,
    data,
    error,
    loadMore,
    hasMore,
  };
};

export interface ListContentProps {
  selectedLabels: string[];
  selectedAgents: string[];
  selectedStatuses: EventStatuses[];
  includeHidden: boolean;
  search: string;
}

const ListContent: React.FC<ListContentProps> = ({ selectedLabels, selectedAgents, selectedStatuses, includeHidden, search }) => {
  

  const filter = Luq(
    <Luq.And>
      {
        !includeHidden && <Luq.Eq field="hidden" value={false} />
      }
      {
        selectedLabels.length > 0 && (
          <Luq.In field="label" values={selectedLabels} />
        )
      }
      {
        selectedAgents.length > 0 && (
          <Luq.In field="agentId" values={selectedAgents} />
        )
      }
      {
        selectedStatuses.length > 0 && (
          <Luq.In field="status.status" values={selectedStatuses} />
        )
      }
      {
        search !== '' && (
          <Luq.Or>
            <Luq.Like field="identifier" pattern={`%${search}%`} caseInsensitive />
            <Luq.Like field="displayName" pattern={`%${search}%`} caseInsensitive />
          </Luq.Or>
        )
      }
    </Luq.And>
  ) as string;

  const {
    loading,
    data,
    error,
    loadMore,
    hasMore,
  } = useInfiniteQuery(filter);

  if (loading) {
    return <Centered>
      <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </Centered>;
  }

  if (!data || error) {
    return <code>{JSON.stringify(error, null, 2)}</code>;
  }

  return (
    <Flow direction="col" maximize>
      <Item type="spreadable" option="scrollable">
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={<Centered key={0}>
            <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)', margin: '1em' }} />
          </Centered>}
          useWindow={false}
        >
          <List data={data} barDisplay={barDisplayBig} />
        </InfiniteScroll>
      </Item>
    </Flow>
  );
};

export default ListContent;
