import * as Types from "./gqlTypes";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {} as const;

export const ApplyEntityDocument = gql`
  mutation ApplyEntity(
    $identifier: String!
    $agent: String
    $displayName: String
    $label: String
    $hidden: Boolean
  ) {
    applyEntity(
      identifier: $identifier
      agent: $agent
      displayName: $displayName
      label: $label
      hidden: $hidden
    ) {
      id
      _updates
    }
  }
`;
export type ApplyEntityMutationFn = Apollo.MutationFunction<
  Types.ApplyEntityMutation,
  Types.ApplyEntityMutationVariables
>;
export type ApplyEntityProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.ApplyEntityMutation,
    Types.ApplyEntityMutationVariables
  >;
} & TChildProps;
export function withApplyEntity<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.ApplyEntityMutation,
    Types.ApplyEntityMutationVariables,
    ApplyEntityProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.ApplyEntityMutation,
    Types.ApplyEntityMutationVariables,
    ApplyEntityProps<TChildProps, TDataName>
  >(ApplyEntityDocument, {
    alias: "applyEntity",
    ...operationOptions,
  });
}

/**
 * __useApplyEntityMutation__
 *
 * To run a mutation, you first call `useApplyEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyEntityMutation, { data, loading, error }] = useApplyEntityMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      agent: // value for 'agent'
 *      displayName: // value for 'displayName'
 *      label: // value for 'label'
 *      hidden: // value for 'hidden'
 *   },
 * });
 */
export function useApplyEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ApplyEntityMutation,
    Types.ApplyEntityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ApplyEntityMutation,
    Types.ApplyEntityMutationVariables
  >(ApplyEntityDocument, options);
}
export type ApplyEntityMutationHookResult = ReturnType<
  typeof useApplyEntityMutation
>;
export type ApplyEntityMutationResult =
  Apollo.MutationResult<Types.ApplyEntityMutation>;
export type ApplyEntityMutationOptions = Apollo.BaseMutationOptions<
  Types.ApplyEntityMutation,
  Types.ApplyEntityMutationVariables
>;
export const RemoveEntityDocument = gql`
  mutation RemoveEntity($identifier: String!) {
    removeEntity(identifier: $identifier) {
      _updates
    }
  }
`;
export type RemoveEntityMutationFn = Apollo.MutationFunction<
  Types.RemoveEntityMutation,
  Types.RemoveEntityMutationVariables
>;
export type RemoveEntityProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.RemoveEntityMutation,
    Types.RemoveEntityMutationVariables
  >;
} & TChildProps;
export function withRemoveEntity<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.RemoveEntityMutation,
    Types.RemoveEntityMutationVariables,
    RemoveEntityProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.RemoveEntityMutation,
    Types.RemoveEntityMutationVariables,
    RemoveEntityProps<TChildProps, TDataName>
  >(RemoveEntityDocument, {
    alias: "removeEntity",
    ...operationOptions,
  });
}

/**
 * __useRemoveEntityMutation__
 *
 * To run a mutation, you first call `useRemoveEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEntityMutation, { data, loading, error }] = useRemoveEntityMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useRemoveEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RemoveEntityMutation,
    Types.RemoveEntityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RemoveEntityMutation,
    Types.RemoveEntityMutationVariables
  >(RemoveEntityDocument, options);
}
export type RemoveEntityMutationHookResult = ReturnType<
  typeof useRemoveEntityMutation
>;
export type RemoveEntityMutationResult =
  Apollo.MutationResult<Types.RemoveEntityMutation>;
export type RemoveEntityMutationOptions = Apollo.BaseMutationOptions<
  Types.RemoveEntityMutation,
  Types.RemoveEntityMutationVariables
>;
export const FindEntityMetricsDependenciesDocument = gql`
  query FindEntityMetricsDependencies(
    $findMetricAggregatorManagerFilter: String!
  ) {
    findMetricAggregatorManager(filter: $findMetricAggregatorManagerFilter) {
      id
      dependencies {
        id
        metric {
          entityId
        }
      }
    }
  }
`;
export type FindEntityMetricsDependenciesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindEntityMetricsDependenciesQuery,
    Types.FindEntityMetricsDependenciesQueryVariables
  >;
} & TChildProps;
export function withFindEntityMetricsDependencies<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindEntityMetricsDependenciesQuery,
    Types.FindEntityMetricsDependenciesQueryVariables,
    FindEntityMetricsDependenciesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindEntityMetricsDependenciesQuery,
    Types.FindEntityMetricsDependenciesQueryVariables,
    FindEntityMetricsDependenciesProps<TChildProps, TDataName>
  >(FindEntityMetricsDependenciesDocument, {
    alias: "findEntityMetricsDependencies",
    ...operationOptions,
  });
}

/**
 * __useFindEntityMetricsDependenciesQuery__
 *
 * To run a query within a React component, call `useFindEntityMetricsDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEntityMetricsDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEntityMetricsDependenciesQuery({
 *   variables: {
 *      findMetricAggregatorManagerFilter: // value for 'findMetricAggregatorManagerFilter'
 *   },
 * });
 */
export function useFindEntityMetricsDependenciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindEntityMetricsDependenciesQuery,
    Types.FindEntityMetricsDependenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindEntityMetricsDependenciesQuery,
    Types.FindEntityMetricsDependenciesQueryVariables
  >(FindEntityMetricsDependenciesDocument, options);
}
export function useFindEntityMetricsDependenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindEntityMetricsDependenciesQuery,
    Types.FindEntityMetricsDependenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindEntityMetricsDependenciesQuery,
    Types.FindEntityMetricsDependenciesQueryVariables
  >(FindEntityMetricsDependenciesDocument, options);
}
export type FindEntityMetricsDependenciesQueryHookResult = ReturnType<
  typeof useFindEntityMetricsDependenciesQuery
>;
export type FindEntityMetricsDependenciesLazyQueryHookResult = ReturnType<
  typeof useFindEntityMetricsDependenciesLazyQuery
>;
export type FindEntityMetricsDependenciesQueryResult = Apollo.QueryResult<
  Types.FindEntityMetricsDependenciesQuery,
  Types.FindEntityMetricsDependenciesQueryVariables
>;
export const FindEntityStatusDependenciesDocument = gql`
  query FindEntityStatusDependencies(
    $findStatusAggregatorManagerFilter: String!
  ) {
    findStatusAggregatorManager(filter: $findStatusAggregatorManagerFilter) {
      id
      rules {
        id
        status {
          id
          entityId
        }
      }
    }
  }
`;
export type FindEntityStatusDependenciesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindEntityStatusDependenciesQuery,
    Types.FindEntityStatusDependenciesQueryVariables
  >;
} & TChildProps;
export function withFindEntityStatusDependencies<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindEntityStatusDependenciesQuery,
    Types.FindEntityStatusDependenciesQueryVariables,
    FindEntityStatusDependenciesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindEntityStatusDependenciesQuery,
    Types.FindEntityStatusDependenciesQueryVariables,
    FindEntityStatusDependenciesProps<TChildProps, TDataName>
  >(FindEntityStatusDependenciesDocument, {
    alias: "findEntityStatusDependencies",
    ...operationOptions,
  });
}

/**
 * __useFindEntityStatusDependenciesQuery__
 *
 * To run a query within a React component, call `useFindEntityStatusDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEntityStatusDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEntityStatusDependenciesQuery({
 *   variables: {
 *      findStatusAggregatorManagerFilter: // value for 'findStatusAggregatorManagerFilter'
 *   },
 * });
 */
export function useFindEntityStatusDependenciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindEntityStatusDependenciesQuery,
    Types.FindEntityStatusDependenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindEntityStatusDependenciesQuery,
    Types.FindEntityStatusDependenciesQueryVariables
  >(FindEntityStatusDependenciesDocument, options);
}
export function useFindEntityStatusDependenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindEntityStatusDependenciesQuery,
    Types.FindEntityStatusDependenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindEntityStatusDependenciesQuery,
    Types.FindEntityStatusDependenciesQueryVariables
  >(FindEntityStatusDependenciesDocument, options);
}
export type FindEntityStatusDependenciesQueryHookResult = ReturnType<
  typeof useFindEntityStatusDependenciesQuery
>;
export type FindEntityStatusDependenciesLazyQueryHookResult = ReturnType<
  typeof useFindEntityStatusDependenciesLazyQuery
>;
export type FindEntityStatusDependenciesQueryResult = Apollo.QueryResult<
  Types.FindEntityStatusDependenciesQuery,
  Types.FindEntityStatusDependenciesQueryVariables
>;
export const FindOauthProviderDocument = gql`
  query FindOauthProvider(
    $filter: String
    $order: String
    $limit: Int
    $offset: Int
  ) {
    findOauthProvider(
      filter: $filter
      order: $order
      limit: $limit
      offset: $offset
    ) {
      id
      name
    }
  }
`;
export type FindOauthProviderProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindOauthProviderQuery,
    Types.FindOauthProviderQueryVariables
  >;
} & TChildProps;
export function withFindOauthProvider<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindOauthProviderQuery,
    Types.FindOauthProviderQueryVariables,
    FindOauthProviderProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindOauthProviderQuery,
    Types.FindOauthProviderQueryVariables,
    FindOauthProviderProps<TChildProps, TDataName>
  >(FindOauthProviderDocument, {
    alias: "findOauthProvider",
    ...operationOptions,
  });
}

/**
 * __useFindOauthProviderQuery__
 *
 * To run a query within a React component, call `useFindOauthProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOauthProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOauthProviderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFindOauthProviderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FindOauthProviderQuery,
    Types.FindOauthProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindOauthProviderQuery,
    Types.FindOauthProviderQueryVariables
  >(FindOauthProviderDocument, options);
}
export function useFindOauthProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindOauthProviderQuery,
    Types.FindOauthProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindOauthProviderQuery,
    Types.FindOauthProviderQueryVariables
  >(FindOauthProviderDocument, options);
}
export type FindOauthProviderQueryHookResult = ReturnType<
  typeof useFindOauthProviderQuery
>;
export type FindOauthProviderLazyQueryHookResult = ReturnType<
  typeof useFindOauthProviderLazyQuery
>;
export type FindOauthProviderQueryResult = Apollo.QueryResult<
  Types.FindOauthProviderQuery,
  Types.FindOauthProviderQueryVariables
>;
export const GetOauthProviderDocument = gql`
  query GetOauthProvider($id: ID!) {
    getOauthProvider(id: $id) {
      id
      name
      oauthUrl
      clientId
      redirectUri
      scope
    }
  }
`;
export type GetOauthProviderProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetOauthProviderQuery,
    Types.GetOauthProviderQueryVariables
  >;
} & TChildProps;
export function withGetOauthProvider<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetOauthProviderQuery,
    Types.GetOauthProviderQueryVariables,
    GetOauthProviderProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetOauthProviderQuery,
    Types.GetOauthProviderQueryVariables,
    GetOauthProviderProps<TChildProps, TDataName>
  >(GetOauthProviderDocument, {
    alias: "getOauthProvider",
    ...operationOptions,
  });
}

/**
 * __useGetOauthProviderQuery__
 *
 * To run a query within a React component, call `useGetOauthProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOauthProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOauthProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOauthProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetOauthProviderQuery,
    Types.GetOauthProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetOauthProviderQuery,
    Types.GetOauthProviderQueryVariables
  >(GetOauthProviderDocument, options);
}
export function useGetOauthProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetOauthProviderQuery,
    Types.GetOauthProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetOauthProviderQuery,
    Types.GetOauthProviderQueryVariables
  >(GetOauthProviderDocument, options);
}
export type GetOauthProviderQueryHookResult = ReturnType<
  typeof useGetOauthProviderQuery
>;
export type GetOauthProviderLazyQueryHookResult = ReturnType<
  typeof useGetOauthProviderLazyQuery
>;
export type GetOauthProviderQueryResult = Apollo.QueryResult<
  Types.GetOauthProviderQuery,
  Types.GetOauthProviderQueryVariables
>;
export const LoginOauthProviderDocument = gql`
  mutation LoginOauthProvider($id: ID!, $code: String!) {
    loginOauthProvider(id: $id, code: $code) {
      accessToken
      tokenType
      expiresIn
      refreshToken
      createdAt
      boldToken
    }
  }
`;
export type LoginOauthProviderMutationFn = Apollo.MutationFunction<
  Types.LoginOauthProviderMutation,
  Types.LoginOauthProviderMutationVariables
>;
export type LoginOauthProviderProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.LoginOauthProviderMutation,
    Types.LoginOauthProviderMutationVariables
  >;
} & TChildProps;
export function withLoginOauthProvider<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.LoginOauthProviderMutation,
    Types.LoginOauthProviderMutationVariables,
    LoginOauthProviderProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.LoginOauthProviderMutation,
    Types.LoginOauthProviderMutationVariables,
    LoginOauthProviderProps<TChildProps, TDataName>
  >(LoginOauthProviderDocument, {
    alias: "loginOauthProvider",
    ...operationOptions,
  });
}

/**
 * __useLoginOauthProviderMutation__
 *
 * To run a mutation, you first call `useLoginOauthProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginOauthProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginOauthProviderMutation, { data, loading, error }] = useLoginOauthProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginOauthProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LoginOauthProviderMutation,
    Types.LoginOauthProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.LoginOauthProviderMutation,
    Types.LoginOauthProviderMutationVariables
  >(LoginOauthProviderDocument, options);
}
export type LoginOauthProviderMutationHookResult = ReturnType<
  typeof useLoginOauthProviderMutation
>;
export type LoginOauthProviderMutationResult =
  Apollo.MutationResult<Types.LoginOauthProviderMutation>;
export type LoginOauthProviderMutationOptions = Apollo.BaseMutationOptions<
  Types.LoginOauthProviderMutation,
  Types.LoginOauthProviderMutationVariables
>;
export const FindThreadsDocument = gql`
  query FindThreads(
    $filter: String
    $order: String
    $limit: Int
    $offset: Int
  ) {
    findThread(filter: $filter, order: $order, limit: $limit, offset: $offset) {
      id
      resolved
      threadItems {
        id
        event {
          id
          date
          status
        }
        resolve
        comment
      }
    }
  }
`;
export type FindThreadsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindThreadsQuery,
    Types.FindThreadsQueryVariables
  >;
} & TChildProps;
export function withFindThreads<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindThreadsQuery,
    Types.FindThreadsQueryVariables,
    FindThreadsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindThreadsQuery,
    Types.FindThreadsQueryVariables,
    FindThreadsProps<TChildProps, TDataName>
  >(FindThreadsDocument, {
    alias: "findThreads",
    ...operationOptions,
  });
}

/**
 * __useFindThreadsQuery__
 *
 * To run a query within a React component, call `useFindThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindThreadsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFindThreadsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FindThreadsQuery,
    Types.FindThreadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindThreadsQuery,
    Types.FindThreadsQueryVariables
  >(FindThreadsDocument, options);
}
export function useFindThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindThreadsQuery,
    Types.FindThreadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindThreadsQuery,
    Types.FindThreadsQueryVariables
  >(FindThreadsDocument, options);
}
export type FindThreadsQueryHookResult = ReturnType<typeof useFindThreadsQuery>;
export type FindThreadsLazyQueryHookResult = ReturnType<
  typeof useFindThreadsLazyQuery
>;
export type FindThreadsQueryResult = Apollo.QueryResult<
  Types.FindThreadsQuery,
  Types.FindThreadsQueryVariables
>;
export const GetThreadDocument = gql`
  query GetThread($id: ID!) {
    getThread(id: $id) {
      id
      resolved
      threadItems {
        id
        event {
          id
          date
          status
        }
        resolve
        comment
      }
    }
  }
`;
export type GetThreadProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetThreadQuery,
    Types.GetThreadQueryVariables
  >;
} & TChildProps;
export function withGetThread<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetThreadQuery,
    Types.GetThreadQueryVariables,
    GetThreadProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetThreadQuery,
    Types.GetThreadQueryVariables,
    GetThreadProps<TChildProps, TDataName>
  >(GetThreadDocument, {
    alias: "getThread",
    ...operationOptions,
  });
}

/**
 * __useGetThreadQuery__
 *
 * To run a query within a React component, call `useGetThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThreadQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetThreadQuery,
    Types.GetThreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetThreadQuery, Types.GetThreadQueryVariables>(
    GetThreadDocument,
    options
  );
}
export function useGetThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetThreadQuery,
    Types.GetThreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetThreadQuery,
    Types.GetThreadQueryVariables
  >(GetThreadDocument, options);
}
export type GetThreadQueryHookResult = ReturnType<typeof useGetThreadQuery>;
export type GetThreadLazyQueryHookResult = ReturnType<
  typeof useGetThreadLazyQuery
>;
export type GetThreadQueryResult = Apollo.QueryResult<
  Types.GetThreadQuery,
  Types.GetThreadQueryVariables
>;
export const FindEntityDocument = gql`
  query FindEntity($filter: String, $order: String, $limit: Int, $offset: Int) {
    findEntity(filter: $filter, order: $order, limit: $limit, offset: $offset) {
      id
      identifier
      displayName
      label
      hidden
      agent {
        id
        identifier
        displayName
      }
      status {
        id
        identifier
        status
        statusDate
        management
      }
      metrics {
        id
        identifier
        displayName
        value
        valueDate
        display
        management
      }
      links {
        id
        identifier
        name
        url
      }
      alerting
    }
  }
`;
export type FindEntityProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindEntityQuery,
    Types.FindEntityQueryVariables
  >;
} & TChildProps;
export function withFindEntity<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindEntityQuery,
    Types.FindEntityQueryVariables,
    FindEntityProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindEntityQuery,
    Types.FindEntityQueryVariables,
    FindEntityProps<TChildProps, TDataName>
  >(FindEntityDocument, {
    alias: "findEntity",
    ...operationOptions,
  });
}

/**
 * __useFindEntityQuery__
 *
 * To run a query within a React component, call `useFindEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEntityQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFindEntityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FindEntityQuery,
    Types.FindEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.FindEntityQuery, Types.FindEntityQueryVariables>(
    FindEntityDocument,
    options
  );
}
export function useFindEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindEntityQuery,
    Types.FindEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindEntityQuery,
    Types.FindEntityQueryVariables
  >(FindEntityDocument, options);
}
export type FindEntityQueryHookResult = ReturnType<typeof useFindEntityQuery>;
export type FindEntityLazyQueryHookResult = ReturnType<
  typeof useFindEntityLazyQuery
>;
export type FindEntityQueryResult = Apollo.QueryResult<
  Types.FindEntityQuery,
  Types.FindEntityQueryVariables
>;
export const FindEventsDocument = gql`
  query FindEvents($eventListFilter: String!, $eventListOrder: String!) {
    findEventList: findEvent(filter: $eventListFilter, order: $eventListOrder) {
      id
      date
      status
    }
  }
`;
export type FindEventsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindEventsQuery,
    Types.FindEventsQueryVariables
  >;
} & TChildProps;
export function withFindEvents<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindEventsQuery,
    Types.FindEventsQueryVariables,
    FindEventsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindEventsQuery,
    Types.FindEventsQueryVariables,
    FindEventsProps<TChildProps, TDataName>
  >(FindEventsDocument, {
    alias: "findEvents",
    ...operationOptions,
  });
}

/**
 * __useFindEventsQuery__
 *
 * To run a query within a React component, call `useFindEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventsQuery({
 *   variables: {
 *      eventListFilter: // value for 'eventListFilter'
 *      eventListOrder: // value for 'eventListOrder'
 *   },
 * });
 */
export function useFindEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindEventsQuery,
    Types.FindEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.FindEventsQuery, Types.FindEventsQueryVariables>(
    FindEventsDocument,
    options
  );
}
export function useFindEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindEventsQuery,
    Types.FindEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindEventsQuery,
    Types.FindEventsQueryVariables
  >(FindEventsDocument, options);
}
export type FindEventsQueryHookResult = ReturnType<typeof useFindEventsQuery>;
export type FindEventsLazyQueryHookResult = ReturnType<
  typeof useFindEventsLazyQuery
>;
export type FindEventsQueryResult = Apollo.QueryResult<
  Types.FindEventsQuery,
  Types.FindEventsQueryVariables
>;
export const FindEventsWithThreadItemsDocument = gql`
  query FindEventsWithThreadItems(
    $eventListFilter: String!
    $eventListOrder: String!
  ) {
    findEventList: findEvent(filter: $eventListFilter, order: $eventListOrder) {
      id
      date
      status
      entity {
        id
        identifier
        displayName
      }
      threadItem {
        id
        threadId
        resolve
        comment
      }
    }
  }
`;
export type FindEventsWithThreadItemsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindEventsWithThreadItemsQuery,
    Types.FindEventsWithThreadItemsQueryVariables
  >;
} & TChildProps;
export function withFindEventsWithThreadItems<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindEventsWithThreadItemsQuery,
    Types.FindEventsWithThreadItemsQueryVariables,
    FindEventsWithThreadItemsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindEventsWithThreadItemsQuery,
    Types.FindEventsWithThreadItemsQueryVariables,
    FindEventsWithThreadItemsProps<TChildProps, TDataName>
  >(FindEventsWithThreadItemsDocument, {
    alias: "findEventsWithThreadItems",
    ...operationOptions,
  });
}

/**
 * __useFindEventsWithThreadItemsQuery__
 *
 * To run a query within a React component, call `useFindEventsWithThreadItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventsWithThreadItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventsWithThreadItemsQuery({
 *   variables: {
 *      eventListFilter: // value for 'eventListFilter'
 *      eventListOrder: // value for 'eventListOrder'
 *   },
 * });
 */
export function useFindEventsWithThreadItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindEventsWithThreadItemsQuery,
    Types.FindEventsWithThreadItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindEventsWithThreadItemsQuery,
    Types.FindEventsWithThreadItemsQueryVariables
  >(FindEventsWithThreadItemsDocument, options);
}
export function useFindEventsWithThreadItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindEventsWithThreadItemsQuery,
    Types.FindEventsWithThreadItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindEventsWithThreadItemsQuery,
    Types.FindEventsWithThreadItemsQueryVariables
  >(FindEventsWithThreadItemsDocument, options);
}
export type FindEventsWithThreadItemsQueryHookResult = ReturnType<
  typeof useFindEventsWithThreadItemsQuery
>;
export type FindEventsWithThreadItemsLazyQueryHookResult = ReturnType<
  typeof useFindEventsWithThreadItemsLazyQuery
>;
export type FindEventsWithThreadItemsQueryResult = Apollo.QueryResult<
  Types.FindEventsWithThreadItemsQuery,
  Types.FindEventsWithThreadItemsQueryVariables
>;
export const FindDrawerItemsDocument = gql`
  query FindDrawerItems($orderLabels: String, $orderAgents: String) {
    findLabel(order: $orderLabels) {
      id
      name
    }
    findAgent(order: $orderAgents) {
      id
      identifier
      displayName
    }
  }
`;
export type FindDrawerItemsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindDrawerItemsQuery,
    Types.FindDrawerItemsQueryVariables
  >;
} & TChildProps;
export function withFindDrawerItems<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindDrawerItemsQuery,
    Types.FindDrawerItemsQueryVariables,
    FindDrawerItemsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindDrawerItemsQuery,
    Types.FindDrawerItemsQueryVariables,
    FindDrawerItemsProps<TChildProps, TDataName>
  >(FindDrawerItemsDocument, {
    alias: "findDrawerItems",
    ...operationOptions,
  });
}

/**
 * __useFindDrawerItemsQuery__
 *
 * To run a query within a React component, call `useFindDrawerItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDrawerItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDrawerItemsQuery({
 *   variables: {
 *      orderLabels: // value for 'orderLabels'
 *      orderAgents: // value for 'orderAgents'
 *   },
 * });
 */
export function useFindDrawerItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FindDrawerItemsQuery,
    Types.FindDrawerItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindDrawerItemsQuery,
    Types.FindDrawerItemsQueryVariables
  >(FindDrawerItemsDocument, options);
}
export function useFindDrawerItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindDrawerItemsQuery,
    Types.FindDrawerItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindDrawerItemsQuery,
    Types.FindDrawerItemsQueryVariables
  >(FindDrawerItemsDocument, options);
}
export type FindDrawerItemsQueryHookResult = ReturnType<
  typeof useFindDrawerItemsQuery
>;
export type FindDrawerItemsLazyQueryHookResult = ReturnType<
  typeof useFindDrawerItemsLazyQuery
>;
export type FindDrawerItemsQueryResult = Apollo.QueryResult<
  Types.FindDrawerItemsQuery,
  Types.FindDrawerItemsQueryVariables
>;
