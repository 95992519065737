import { StatusNativeEvent } from './types';
import { statusForceIssue } from './defines';
import { EventStatuses } from '../types';

export interface Issue {
  startDate: Date;
  stopDate?: Date;
  status: EventStatuses;
  threadId: string;
}

export const getIssuesList = (nativeEvents: StatusNativeEvent[]): Issue[] => {

  const issueList: Issue[] = [];

  let issueRunning: boolean = false;

  for (const event of nativeEvents) {
    if (issueRunning) {
      const currentIssue = issueList[issueList.length - 1];

      if (event.status !== currentIssue.status || (event.threadId !== currentIssue.threadId)) {
        issueList[issueList.length - 1].stopDate = event.date;

        if (statusForceIssue[event.status]) {
          issueList.push({
            startDate: event.date,
            status: event.status,
            threadId: event.threadId,
          });
        }
        else {
          issueRunning = false;
        }
      }
    }
    else {
      if (statusForceIssue[event.status]) {
        issueList.push({
          startDate: event.date,
          status: event.status,
          threadId: event.threadId,
        });

        issueRunning = true;
      }
    }
  }

  return issueList;
};
