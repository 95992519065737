import React from 'react';
import { Flow, Item } from '@guy/flex-flow';
import { FindThreadsQuery } from '../../bold/gqlTypes';
import { statusDisplay, OneOf } from '@statusgate/common';
import { panelClass } from '../../style/panelClass';
import { css, cx } from '@emotion/css';
import { colorClasses } from '../StatusBar/StatusBar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMemo } from 'react';


const threadItemWrapper = css`
  position: relative;
  padding: 10px;
  padding-left: 34px;
`;

const threadItemResolveMark = css`
  position: absolute;
  top: 10px;
  left: 5px;
  background-color: #ffffff;
  width: 24px;
  height: 24px;
`;

const threadItemMark = css`
  position: absolute;
  top: 15px;
  left: 10px;
  background-color: #cccccc;
  border-radius: 7px;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
`;

const feedBarClass = css`
  position: absolute;
  top: 22px;
  left: 16px;
  width: 2px;
  height: calc(100%);
  background-color: #cccccc;
`;

const threadItemTitle = css`
  font-size: 1.1em;
  margin-bottom: 0.5em;
`;

const threadItemDate = css`
  font-size: 0.875em;
  color: #555555;
`;

export interface ThreadItemProps {
  threadItem: OneOf<OneOf<FindThreadsQuery['findThread']>['threadItems']>;
  last: boolean;
}

export const ThreadItem: React.FC<ThreadItemProps> = ({ threadItem, last }) => {
  return (
    <div className={threadItemWrapper}>
      {
        !last && <div className={feedBarClass} />
      }
      {
        threadItem.resolve ? (
          <div className={threadItemResolveMark}>
            <CheckCircleIcon color="success" />
          </div>
        ) : <div className={threadItemMark} />
      }
      <Flow direction="row">
        <Item type="fitted" className={cx(threadItemTitle, colorClasses[threadItem.event.status])}>
          {statusDisplay[threadItem.event.status]}
        </Item>
        <Item type="spreadable"></Item>
        <Item type="fitted" className={threadItemDate}>
          {threadItem.event.date.toLocaleString()}
        </Item>
      </Flow>
      {threadItem.comment}
    </div>
  );
};

const threadClass = css`
  width: 920px;
  margin: 1em auto;
`;

export interface ThreadProps {
  thread: OneOf<FindThreadsQuery['findThread']>;
}

export const Thread: React.FC<ThreadProps> = ({ thread }) => {

  const sortedItems = useMemo(() => {
    return [...thread.threadItems].sort((a, b) => {
      return b.event.date.getTime() - a.event.date.getTime();
    });
  }, [thread.threadItems]);

  return (
    <div className={cx(threadClass, panelClass)}>
      {
        sortedItems.map((threadItem, index) => <ThreadItem key={threadItem.id} threadItem={threadItem} last={index === sortedItems.length - 1} />)
      }
    </div>
  );
};
