import { css } from '@emotion/css';
import { Flow, Item } from '@guy/flex-flow';
import React, { useMemo } from 'react';
import { StatusEvent, getUptime, intervalsFunctions, IntervalUnit } from '@statusgate/common';
import { Size, sizesParams } from '../StatusBarSizes';

const legendTextClass = css`
  color: #999999;
`;

const legendFillerClass = css`
  height: 1px;
  background-color: #cccccc;
  margin-top: 0.675em;
  margin-left: 0.75em;
  margin-right: 0.75em;
`;


export interface StatusBarLegendProps {
  size: Size;
  events: StatusEvent[];
  min: Date;
  max: Date;
  intervalUnit: IntervalUnit;
}

const StatusBarLegend: React.FC<StatusBarLegendProps> = ({ size, events, min, max, intervalUnit }) => {

  const uptime = useMemo(() => getUptime(events, min, max), [events, min, max]);

  return (
    <Flow direction="row" className={sizesParams[size].legendClass}>
      <Item type="fitted" className={legendTextClass}>{intervalsFunctions[intervalUnit].display(min, 'min')}</Item>
      {
        uptime !== null && <>
          <Item type="spreadable" className={legendFillerClass} />
          <Item type="fitted" className={legendTextClass}>{(uptime * 100).toFixed(uptime === 0 || uptime === 1 ? 0 : 4)}% uptime</Item>
        </>
      }
      <Item type="spreadable" className={legendFillerClass} />
      <Item type="fitted" className={legendTextClass}>{intervalsFunctions[intervalUnit].display(max, 'max')}</Item>
    </Flow>
  );
};

export default StatusBarLegend;
