import React from 'react';
import { useGetThreadQuery } from '../../bold/gql';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Centered } from '@guy/flex-flow';
import { Thread } from '../../components/Thread/Threads';

export interface ThreadPageProps {
}

const ThreadPage: React.FC<ThreadPageProps> = () => {

  const params = useParams();
  const threadId = params.threadId;

  const { data, error, loading } = useGetThreadQuery({
    variables: {
      id: threadId as string,
    },
    skip: threadId === undefined,
  });

  if (loading) {
    return <Centered>
      <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </Centered>;
  }

  if (!data || !data.getThread || error) {
    return <code>{JSON.stringify(error, null, 2)}</code>;
  }

  const thread = data.getThread;

  return <div>
    <Thread thread={thread}></Thread>
  </div>;
};

export default ThreadPage;
