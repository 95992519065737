import React from 'react';
import { useFindThreadsQuery, useFindEntityQuery } from '../../bold/gql';
import { barDisplayBig, ListItem } from '../../components/EntityList/List';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Centered } from '@guy/flex-flow';
import Luq from '@luq/core';
import { FindEntityQuery } from '../../bold/gqlTypes';
import { OneOf } from '@statusgate/common';
import { useEntityDependencies } from '../../hooks/useEntityDependencies';
import { Thread } from '../../components/Thread/Threads';

export interface EntityThreadsProps {
  entity: OneOf<FindEntityQuery['findEntity']>;
}

const EntityThreads: React.FC<EntityThreadsProps> = ({ entity }) => {

  const { data: dependenciesData } = useEntityDependencies(entity, true);

  const entityIds = [entity.id, ...dependenciesData];

  const { data, error, loading } = useFindThreadsQuery({
    variables: {
      filter: Luq(<Luq.And>
        <Luq.In field="entityId" values={entityIds} />
        <Luq.Eq field="resolved" value={false} />
      </Luq.And>),
    },
  });

  if (loading) {
    return <Centered>
      <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </Centered>;
  }

  if (!data || !data.findThread || error) {
    return <code>{JSON.stringify(error, null, 2)}</code>;
  }

  return <>
    {
      data.findThread.map(thread => <Thread key={thread.id} thread={thread} />)
    }
  </>;
};

export interface EntityPageProps {
}

const EntityPage: React.FC<EntityPageProps> = () => {

  const params = useParams();
  const entityId = params.entityId;

  const { data, error, loading } = useFindEntityQuery({
    variables: {
      filter: Luq(<Luq.Eq field="identifier" value={entityId as string} />),
      limit: 1,
    },
    skip: entityId === undefined,
  });

  if (loading) {
    return <Centered>
      <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </Centered>;
  }

  if (!data || !data.findEntity || error) {
    return <code>{JSON.stringify(error, null, 2)}</code>;
  }

  const entity = data.findEntity.length > 0 ? data.findEntity[0] : null;

  if (entity === null) {
    return <code>Entity not found</code>;
  }

  return <div>
    <ListItem entity={entity} barDisplay={barDisplayBig} />
    <EntityThreads entity={entity} />
  </div>;
};

export default EntityPage;
