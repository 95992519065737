export type CompareFn<DataType, ValueType> = (item: DataType, value: ValueType) => number;

export const bisect = <DataType, ValueType = DataType>(
    direction: 'left' | 'right',
    list: DataType[], 
    value: ValueType, 
    compareFn: CompareFn<DataType, ValueType>, 
    start: number = 0, 
    end: number = list.length
  ): number => {
    if (start < end) {
      do {
        const mid = (start + end) >>> 1;
        const compared = compareFn(list[mid], value);
        if ((direction === 'left' && compared < 0) || (direction === 'right' && compared <= 0)){ 
          start = mid + 1;
        }
        else {
          end = mid;
        }
      } while (start < end);
    }
    
    return start;
};
