import { css } from '@emotion/css';
import React, { useMemo } from 'react';
import { EventStatuses, StatusEvent, IntervalUnit, generateTicks, TickInfos } from '@statusgate/common';
import { Size } from '../StatusBarSizes';
import { BarTickData } from '../StatusBarTypes';
import StatusBarTick from '../StatusBarTick/StatusBarTick';

const ticksWrapperClass = css`
  margin-bottom: -5px;
`;

export interface StatusBarTicksProps {
  size: Size;
  events: StatusEvent[];
  min: Date;
  max: Date;
  interval: number;
  intervalUnit: IntervalUnit;
  tooltipTick: BarTickData | null;
  setHoveredTick: (hoveredTick: BarTickData | null) => void;
}

const StatusBarTicks: React.FC<StatusBarTicksProps> = ({ size, events, min, max, interval, intervalUnit, tooltipTick, setHoveredTick }) => {
  const ticks = useMemo(() => generateTicks(events, min, max, interval, intervalUnit), [events, min, max, interval, intervalUnit]);

  const getTickDecoration = (tick: TickInfos): ('space' | 'arrow')[] => {
    const decorations: ('space' | 'arrow')[] = [];

    if (tooltipTick !== null && tooltipTick.tick.key === tick.key) {
      decorations.push('arrow');
    }

    return decorations;
  };

  return (
    <div className={ticksWrapperClass}>
      {
        ticks.map((tick, index) => <StatusBarTick
          size={size}
          key={tick.key}
          status={tick.status}
          color={tick.color}
          decorations={getTickDecoration(tick)}
          onHover={(hover, target) => {
            setHoveredTick(hover && tick.status !== EventStatuses.Undefined ? { tick, index, target } : null);
          }}
        />)
      }
    </div>
  )
};

export default StatusBarTicks;
