export const lerp = (a: number, b: number, t: number) => {
  return a + (b - a) * t;
};

export const invLerp = (a: number, b: number, value: number) => {
  return (value - a) / (b - a);
};

export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

export const discretize = (value: number, min: number, max: number) => {
  return Math.round(clamp(value, min, max));
};
