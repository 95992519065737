export type MutexUnlock = () => void;
export type MutexLock = () => Promise<MutexUnlock>;

export const createMutex = (): MutexLock => {
  let current = Promise.resolve();

  const lock = (): Promise<MutexUnlock> => {
    let _resolve: () => void;

    const promise = new Promise<void>(resolve => {
      _resolve = resolve;
    });

    const unlock = current.then(() => _resolve);

    current = promise;

    return unlock;
  };

  return lock;
};

export type IdentifiedMutexLock = (id: string) => Promise<MutexUnlock>;

export const createIdentifiedMutex = (): IdentifiedMutexLock => {

  type MutexInfos = { mutex: MutexLock, count: number };

  const mutexMap = new Map<string, MutexInfos>();

  return async (id: string) => {
    const retrievedMutexInfos = mutexMap.get(id);
    let mutexInfos: MutexInfos;

    if (retrievedMutexInfos === undefined) {
      mutexInfos = {
        mutex: createMutex(),
        count: 1,
      };

      mutexMap.set(id, mutexInfos);
    }
    else {
      mutexInfos = retrievedMutexInfos;
      ++mutexInfos.count;
    }

    const unlock = await mutexInfos.mutex();

    return () => {
      --mutexInfos.count;

      if (mutexInfos.count === 0) {
        mutexMap.delete(id);
      }

      unlock();
    };
  };
};

export const withLock = async <ReturnType>(lock: Promise<MutexUnlock>, handler: () => ReturnType | Promise<ReturnType>): Promise<ReturnType> => {
  const unlock = await lock;

  try {
    const ret = await handler();
    unlock();
    return ret;
  }
  catch (err) {
    unlock();
    throw err;
  }
};