import { bisect } from '../helpers/bisect';
import { StatusEvent, TickInfos } from './types';
import { colorScheme, getFadeColor, intervalsFunctions, IntervalUnit, statusOutage, statusPrecedence } from './defines';
import { getEventsRange } from './getEventsRange';
import { EventStatuses } from '../types';


const getTickIndex = (ticks: TickInfos[], date: Date) => {
  const index = bisect('right', ticks, date, (item, value) => {
    return item.startDate.getTime() - value.getTime();
  }) - 1;

  return index;
};

const generateBaseTicks = (min: Date, max: Date, interval: number, intervalUnit: IntervalUnit): TickInfos[] => {

  const get = intervalsFunctions[intervalUnit].get;
  const set = intervalsFunctions[intervalUnit].set;

  const ticks: Partial<TickInfos>[] = [];

  let currentDate = min;

  while (currentDate.getTime() < max.getTime()) {

    if (ticks.length > 0) {
      ticks[ticks.length - 1].stopDate = currentDate;
    }

    ticks.push({
      startDate: currentDate,
      key: currentDate.getTime(),
      status: EventStatuses.Undefined,
      nEvents: 0,
      totalTime: 0,
      outageTime: 0,
      color: null,
    });

    currentDate = new Date(currentDate);
    set.call(currentDate, get.call(currentDate) + interval);
  }

  if (ticks.length > 0) {
    ticks[ticks.length - 1].stopDate = currentDate;
  }

  return ticks as TickInfos[];
};

export const generateTicks = (events: Omit<StatusEvent, 'nativeEvents'>[], min: Date, max: Date, interval: number, intervalUnit: IntervalUnit): TickInfos[] => {

  const ticks = generateBaseTicks(min, max, interval, intervalUnit);

  const { initialStatusEventIndex, scopeEvents } = getEventsRange(events, min, max);

  let currentStatusStart: number = 0;
  let currentEventDate: Date | null;
  let currentStatus: EventStatuses;

  if (initialStatusEventIndex === -1) {
    currentEventDate = null;
    currentStatus = EventStatuses.Undefined;
  }
  else {
    const event = events[initialStatusEventIndex];
    currentEventDate = event.date;
    currentStatus = event.status;
  }

  const fillTicks = (to: number, toEvent: Omit<StatusEvent, 'nativeEvents'> | null) => {
    for (let i = currentStatusStart; i <= to; ++i) {
      if (statusPrecedence[currentStatus] > statusPrecedence[ticks[i].status]) {
        ticks[i].status = currentStatus;
      }

      const fromEventTime = (currentEventDate === null ? 0 : currentEventDate.getTime());
      const currentTickTimeStart = ticks[i].startDate.getTime();
      const timeStart = Math.max(fromEventTime, currentTickTimeStart);

      const toEventTime = (toEvent === null ? new Date() : toEvent.date).getTime();
      const currentTickTimeStop = ticks[i].stopDate.getTime();
      const timeStop = Math.min(toEventTime, currentTickTimeStop);

      if (currentStatus !== EventStatuses.Undefined) {

        const eventTimeInTick = timeStop - timeStart;
        
        ticks[i].totalTime += eventTimeInTick;

        if (statusOutage[currentStatus]) {
          ticks[i].outageTime += eventTimeInTick;
        }
      }
    }

    if (toEvent !== null) {
      ++ticks[to].nEvents;
    }
  };

  for (const event of scopeEvents) {
    const eventIndex = getTickIndex(ticks, event.date);

    fillTicks(eventIndex, event);

    currentStatusStart = eventIndex;
    currentEventDate = event.date;
    currentStatus = event.status;
  }

  fillTicks(ticks.length - 1, null);

  for (const tick of ticks) {
    if (statusOutage[tick.status]) {
      const outagePercent = tick.outageTime / tick.totalTime;

      tick.color = getFadeColor(outagePercent);
    }
    else if (tick.status === EventStatuses.DegradedPerformance) {
      tick.color = colorScheme[EventStatuses.Operational];
    }
  }

  return ticks;
};
