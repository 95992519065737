import { StatusEvent } from "./types";

export const getEventsRange = <T extends Omit<StatusEvent, 'nativeEvents'>>(events: T[], min: Date, max: Date) => {

  const minTime = min.getTime();
  const maxTime = max.getTime();

  let initialStatusEventIndex = -1;
  let firstEventIndex = -1;
  let lastEventIndex = -1;

  for (let i = 0; i < events.length; ++i) {
    const event = events[i];
    const eventTime = event.date.getTime();

    if (eventTime < minTime) {
      initialStatusEventIndex = i;
    }

    if (eventTime >= minTime && eventTime < maxTime) {
      if (firstEventIndex === -1) {
        firstEventIndex = i;
      }

      lastEventIndex = i;
    }
  }

  return {
    initialStatusEventIndex,
    scopeEvents: (firstEventIndex === -1 || lastEventIndex === -1) ? [] : events.slice(firstEventIndex, lastEventIndex + 1),
  };
};
