import React from 'react';
import { Routes, Route, useSearchParams } from "react-router-dom";
import OauthLoginPage from './views/OauthLoginPage/OauthLoginPage';
import OauthVerifyPage from './views/OauthVerifyPage/OauthVerifyPage';
import LoginPage from './views/LoginPage/LoginPage';
import LogoutPage from './views/LogoutPage/LogoutPage';
import MainPage from './views/MainPage/MainPage';
import ListPage from './views/ListPage/ListPage';
import EntityPage from './views/EntityPage/EntityPage';
import ThreadPage from './views/ThreadPage/ThreadPage';
import { getToken, setFloatingToken } from './token';

const App: React.FC = () => {

  const [searchParams] = useSearchParams();
  const floatingToken = searchParams.get('token');
  setFloatingToken(floatingToken);

  const token = getToken();

  return (
    <Routes>
      {
        token === null ? <>
          <Route path="/" element={<LoginPage />} />
          <Route path="/oauth-verify" element={<OauthVerifyPage />} />
          <Route path="/oauth-login/:providerId" element={<OauthLoginPage />} />
        </> : <>
          <Route path="/" element={<MainPage />} />
          <Route path="/list" element={<ListPage />} />
          <Route path="/entity/:entityId" element={<EntityPage />} />
          <Route path="/thread/:threadId" element={<ThreadPage />} />
          <Route path="/logout" element={<LogoutPage />} />
        </>
      }
    </Routes>
  );
};

export default App;
