import { FindEntityStatusDependenciesDocument, useFindEntityQuery, useFindEntityStatusDependenciesQuery } from '../bold/gql';
import Luq from '@luq/core';
import { FindEntityQuery, FindEntityStatusDependenciesQuery, FindEntityStatusDependenciesQueryVariables } from '../bold/gqlTypes';
import { ApolloError, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { OneOf } from '@statusgate/common';
import { useBold } from '@bold/client';
import { useEffect, useState } from 'react';

const fetchEntityDependencies = async (apollo: ApolloClient<NormalizedCacheObject>, statusIds: string[], recursive: boolean, entityIds: Record<string, true>, processedSubStatusIds: Record<string, true> = {}): Promise<void> => {
  const { data } = await apollo.query<FindEntityStatusDependenciesQuery, FindEntityStatusDependenciesQueryVariables>({
    query: FindEntityStatusDependenciesDocument,
    variables: {
      findStatusAggregatorManagerFilter: Luq(<Luq.In field="statusId" values={statusIds} />) as string,
    },
  });

  let hasSubStatus = false;
  const subStatusIds: Record<string, true> = {};

  for (const item of data.findStatusAggregatorManager) {
    for (const rule of item.rules) {
      const status = rule.status;

      if (!(status.id in processedSubStatusIds)) {
        subStatusIds[status.id] = true;
        hasSubStatus = true;
      }
      
      entityIds[status.entityId] = true;
    }
  }

  if (recursive && hasSubStatus) {
    Object.assign(processedSubStatusIds, subStatusIds);

    return fetchEntityDependencies(apollo, Object.keys(subStatusIds), recursive, entityIds, processedSubStatusIds);
  }
};

export const useEntityDependencies = (entity: OneOf<FindEntityQuery['findEntity']>, recursive: boolean, skip: boolean = false) => {
  const [state, setState] = useState<{ data: string[], error: ApolloError | undefined, loading: boolean }>({
    data: [],
    error: undefined,
    loading: !skip,
  });

  const statusId = entity.status.length > 0 ? entity.status[0].id : null;
  const { apollo } = useBold();

  useEffect(() => {
    if (statusId === null) {
      setState({
        data: [],
        error: undefined,
        loading: false,
      });
    }
    else {
      if (!skip) {

        setState({
          data: state.data,
          error: undefined,
          loading: true,
        });

        const entityIds: Record<string, true> = {};

        fetchEntityDependencies(apollo, [statusId], recursive, entityIds)
          .then(() => {
            setState({
              data: Object.keys(entityIds),
              error: undefined,
              loading: false,
            });
          })
          .catch((err: ApolloError) => {
            setState({
              data: [],
              error: err,
              loading: false,
            });
          });
      }
    }

  }, [statusId, recursive, skip]);

  return state;
};


// export const useEntityDependencies = (entity: OneOf<FindEntityQuery['findEntity']>, skip: boolean = false) => {

//   const statusId = entity.status.length > 0 ? entity.status[0].id : null;
//   // const metricIds = entity.metrics.map(metric => metric.id);

//   const { data: statusData, error: statusError, loading: statusLoading } = useFindEntityStatusDependenciesQuery({
//     variables: {
//       findStatusAggregatorManagerFilter: Luq(<Luq.Eq field="statusId" value={statusId} />) as string,
//       // findStatusMetricManagerFilter: Luq(<Luq.Eq field="statusId" value={statusId} />) as string,
//     },
//     skip: skip || statusId == null,
//   });

//   const { apollo } = useBold();

//   apollo.query<FindEntityStatusDependenciesQuery, FindEntityStatusDependenciesQueryVariables>({
//     query: FindEntityStatusDependenciesDocument,
//     variables: {
//       findStatusAggregatorManagerFilter: 
//     },
//   });

//   // const { data: metricsData, error: metricsError, loading: metricsLoading } = useFindEntityMetricsDependenciesQuery({
//   //   variables: {
//   //     findMetricAggregatorManagerFilter: Luq(<Luq.In field="metricId" values={metricIds} />) as string,
//   //   },
//   //   skip: metricIds.length === 0,
//   // });

//   let loading: boolean;
//   let error: ApolloError | null;
//   let entityIds: Record<string, true> = {};

//   if (statusLoading/* || metricsLoading*/) {
//     loading = true;
//     error = null;
//   }
//   else {
//     loading = false;

//     if (statusError) {
//       /*if (metricsError) {
//         error = {
//           ...statusError,
//           ...metricsError,
//         };
//       }
//       else {*/
//         error = statusError;
//       // }
//     }
//     else {
//       /*if (metricsError) {
//         error = metricsError;
//       }
//       else {*/

//         error = null;

//         const findStatusAggregatorManagerData = statusData?.findStatusAggregatorManager ?? [];
//         // const findStatusMetricManagerData = statusData?.findStatusMetricManager ?? [];
//         // const findMetricAggregatorManagerData = metricsData?.findMetricAggregatorManager ?? [];

//         for (const item of findStatusAggregatorManagerData) {
//           const rules = item.rules ?? [];

//           for (const rule of rules) {
//             entityIds[rule.status.entityId] = true;
//           }
//         }

//         // for (const item of findStatusMetricManagerData) {
//         //   entityIds[item.metric.entityId] = true;
//         // }

//         // for (const item of findMetricAggregatorManagerData) {
//         //   const dependencies = item.dependencies ?? [];

//         //   for (const dependency of dependencies) {
//         //     entityIds[dependency.metric.entityId] = true;
//         //   }
//         // }
//       //}
//     }
//   }

//   return {
//     data: Object.keys(entityIds),
//     error,
//     loading,
//   };
// };

export const useEntityDependencyEntities = (entity: OneOf<FindEntityQuery['findEntity']>, recursive: boolean, skip: boolean = false) => {
  const { data: dependenciesData, error: dependenciesError, loading: dependenciesLoading } = useEntityDependencies(entity, recursive, skip);

  const { data, error: entitiesError, loading: entitiesLoading } = useFindEntityQuery({
    variables: {
      filter: Luq(<Luq.In field="id" values={dependenciesData} />),
    },
    skip: dependenciesLoading || dependenciesError !== undefined,
  });

  return {
    data,
    error: dependenciesError !== undefined ? dependenciesError : entitiesError,
    loading: dependenciesLoading || entitiesLoading,
  };
};