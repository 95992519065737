export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
};

export type Agent = {
  __typename?: "Agent";
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  identifier: Scalars["String"];
  serviceAccount: ServiceAccount;
  serviceAccountId: Scalars["ID"];
  url?: Maybe<Scalars["String"]>;
};

export type AgentMetricProvider = {
  __typename?: "AgentMetricProvider";
  agent: Agent;
  agentId: Scalars["ID"];
  generation?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  identifier: Scalars["String"];
  interval: Scalars["Float"];
  metric: Metric;
  metricId: Scalars["ID"];
  metricName: Scalars["String"];
  nTries: Scalars["Int"];
};

export type AgentStatusProvider = {
  __typename?: "AgentStatusProvider";
  agent: Agent;
  agentId: Scalars["ID"];
  generation?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  identifier: Scalars["String"];
  interval: Scalars["Float"];
  nTries: Scalars["Int"];
  status: Status;
  statusId: Scalars["ID"];
  statusName: Scalars["String"];
};

export type ApplyAgent = {
  __typename?: "ApplyAgent";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyAgentMetricProvider = {
  __typename?: "ApplyAgentMetricProvider";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyAgentStatusProvider = {
  __typename?: "ApplyAgentStatusProvider";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyCertificateStatusProvider = {
  __typename?: "ApplyCertificateStatusProvider";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyEndpointStatusProvider = {
  __typename?: "ApplyEndpointStatusProvider";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyEntity = {
  __typename?: "ApplyEntity";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyKubeAgent = {
  __typename?: "ApplyKubeAgent";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyLink = {
  __typename?: "ApplyLink";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyMetric = {
  __typename?: "ApplyMetric";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyMetricAggregatorManager = {
  __typename?: "ApplyMetricAggregatorManager";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyMetricAggregatorManagerDep = {
  __typename?: "ApplyMetricAggregatorManagerDep";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyOauthProvider = {
  __typename?: "ApplyOauthProvider";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyServiceAccount = {
  __typename?: "ApplyServiceAccount";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyStatus = {
  __typename?: "ApplyStatus";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyStatusAggregatorManager = {
  __typename?: "ApplyStatusAggregatorManager";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyStatusAggregatorManagerRule = {
  __typename?: "ApplyStatusAggregatorManagerRule";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type ApplyStatusMetricManager = {
  __typename?: "ApplyStatusMetricManager";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type AuthTokenLoginServiceAccount = {
  __typename?: "AuthTokenLoginServiceAccount";
  _updates: Scalars["String"];
  id: Scalars["ID"];
  token: Scalars["String"];
};

export type CertificateStatusProvider = {
  __typename?: "CertificateStatusProvider";
  generation: Scalars["Int"];
  host: Scalars["String"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  interval: Scalars["Float"];
  minRemainingDays?: Maybe<Scalars["Int"]>;
  nTries: Scalars["Int"];
  remainingDaysMetric?: Maybe<Metric>;
  remainingDaysMetricId?: Maybe<Scalars["ID"]>;
  status: Status;
  statusId: Scalars["ID"];
};

export type CreateLink = {
  __typename?: "CreateLink";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type CreateOauthProvider = {
  __typename?: "CreateOauthProvider";
  _updates: Scalars["String"];
  id: Scalars["ID"];
};

export type DeleteLink = {
  __typename?: "DeleteLink";
  _updates: Scalars["String"];
};

export type DeleteOauthProvider = {
  __typename?: "DeleteOauthProvider";
  _updates: Scalars["String"];
};

export type EndpointStatusProvider = {
  __typename?: "EndpointStatusProvider";
  allow401: Scalars["Boolean"];
  allow403: Scalars["Boolean"];
  codeExpectEnd: Scalars["Int"];
  codeExpectStart: Scalars["Int"];
  generation: Scalars["Int"];
  headers?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  identifier: Scalars["String"];
  interval: Scalars["Float"];
  maxResponseTime?: Maybe<Scalars["Float"]>;
  method: EndpointStatusProviderMethodEnum;
  nTries: Scalars["Int"];
  responseTimeMetric?: Maybe<Metric>;
  responseTimeMetricId?: Maybe<Scalars["ID"]>;
  status: Status;
  statusId: Scalars["ID"];
  url: Scalars["String"];
};

export enum EndpointStatusProviderMethodEnum {
  Get = "GET",
  Post = "POST",
}

export type Entity = {
  __typename?: "Entity";
  agent?: Maybe<Agent>;
  agentId?: Maybe<Scalars["ID"]>;
  alerting?: Maybe<Scalars["Boolean"]>;
  displayName?: Maybe<Scalars["String"]>;
  hidden: Scalars["Boolean"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  links: Array<Link>;
  metrics: Array<Metric>;
  status: Array<Status>;
};

export enum EntityHtmlRenderInputIntervalUnitEnum {
  Days = "DAYS",
  Minutes = "MINUTES",
}

export type Event = {
  __typename?: "Event";
  date: Scalars["DateTime"];
  entity: Entity;
  entityId: Scalars["ID"];
  id: Scalars["ID"];
  old: Scalars["Boolean"];
  status: EventStatusEnum;
  threadItem?: Maybe<ThreadItem>;
  threadItemId?: Maybe<Scalars["ID"]>;
};

export enum EventStatusEnum {
  Degradedperformance = "DEGRADEDPERFORMANCE",
  Maintenance = "MAINTENANCE",
  Majoroutage = "MAJOROUTAGE",
  Operational = "OPERATIONAL",
  Partialoutage = "PARTIALOUTAGE",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
}

export type HtmlRenderEntity = {
  __typename?: "HtmlRenderEntity";
  _updates: Scalars["String"];
  content: Scalars["String"];
  uptime: Scalars["String"];
};

export type KubeAgent = {
  __typename?: "KubeAgent";
  agent: Agent;
  agentId: Scalars["ID"];
  alerting?: Maybe<Scalars["Boolean"]>;
  backupAllowedDowntime: Scalars["Int"];
  backups: Scalars["Boolean"];
  backupsMaxAge?: Maybe<Scalars["Int"]>;
  certificateMinRemainingDays?: Maybe<Scalars["Int"]>;
  diskSpace: Scalars["Boolean"];
  diskSpaceMaxUsedSpace?: Maybe<Scalars["Float"]>;
  endpointAllowedDowntime: Scalars["Int"];
  endpoints: Scalars["Boolean"];
  excludeNamespaces: Array<Scalars["String"]>;
  id: Scalars["ID"];
  identifier: Scalars["String"];
  rancherPrefix?: Maybe<Scalars["String"]>;
};

export type Label = {
  __typename?: "Label";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Link = {
  __typename?: "Link";
  entity: Entity;
  entityId: Scalars["ID"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type LoginOauthProvider = {
  __typename?: "LoginOauthProvider";
  _updates: Scalars["String"];
  accessToken: Scalars["String"];
  boldToken: Scalars["String"];
  createdAt: Scalars["Int"];
  expiresIn: Scalars["Int"];
  refreshToken: Scalars["String"];
  tokenType: Scalars["String"];
};

export type Metric = {
  __typename?: "Metric";
  display: MetricDisplayEnum;
  displayName?: Maybe<Scalars["String"]>;
  entity: Entity;
  entityId: Scalars["ID"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  management: MetricManagementEnum;
  value?: Maybe<Scalars["Float"]>;
  valueDate?: Maybe<Scalars["DateTime"]>;
};

export type MetricAggregatorManager = {
  __typename?: "MetricAggregatorManager";
  dependencies: Array<MetricAggregatorManagerDep>;
  expression: Scalars["String"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  metric: Metric;
  metricId: Scalars["ID"];
};

export type MetricAggregatorManagerDep = {
  __typename?: "MetricAggregatorManagerDep";
  as: Scalars["String"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  manager: MetricAggregatorManager;
  managerId: Scalars["ID"];
  metric: Metric;
  metricId: Scalars["ID"];
};

export enum MetricDisplayEnum {
  Date = "DATE",
  Number = "NUMBER",
  Percent = "PERCENT",
  Seconds = "SECONDS",
  Volumesize = "VOLUMESIZE",
}

export enum MetricManagementEnum {
  Agentmetricprovider = "AGENTMETRICPROVIDER",
  Aggregatormanager = "AGGREGATORMANAGER",
  Certificateprovider = "CERTIFICATEPROVIDER",
  Endpointprovider = "ENDPOINTPROVIDER",
  Free = "FREE",
}

export type Mutation = {
  __typename?: "Mutation";
  applyAgent: ApplyAgent;
  applyAgentMetricProvider: ApplyAgentMetricProvider;
  applyAgentStatusProvider: ApplyAgentStatusProvider;
  applyCertificateStatusProvider: ApplyCertificateStatusProvider;
  applyEndpointStatusProvider: ApplyEndpointStatusProvider;
  applyEntity: ApplyEntity;
  applyKubeAgent: ApplyKubeAgent;
  applyLink: ApplyLink;
  applyMetric: ApplyMetric;
  applyMetricAggregatorManager: ApplyMetricAggregatorManager;
  applyMetricAggregatorManagerDep: ApplyMetricAggregatorManagerDep;
  applyOauthProvider: ApplyOauthProvider;
  applyServiceAccount: ApplyServiceAccount;
  applyStatus: ApplyStatus;
  applyStatusAggregatorManager: ApplyStatusAggregatorManager;
  applyStatusAggregatorManagerRule: ApplyStatusAggregatorManagerRule;
  applyStatusMetricManager: ApplyStatusMetricManager;
  authTokenLoginServiceAccount: AuthTokenLoginServiceAccount;
  createLink: CreateLink;
  createOauthProvider: CreateOauthProvider;
  deleteLink: DeleteLink;
  deleteOauthProvider: DeleteOauthProvider;
  htmlRenderEntity: HtmlRenderEntity;
  loginOauthProvider: LoginOauthProvider;
  removeAgent: RemoveAgent;
  removeAgentMetricProvider: RemoveAgentMetricProvider;
  removeAgentStatusProvider: RemoveAgentStatusProvider;
  removeCertificateStatusProvider: RemoveCertificateStatusProvider;
  removeEndpointStatusProvider: RemoveEndpointStatusProvider;
  removeEntity: RemoveEntity;
  removeKubeAgent: RemoveKubeAgent;
  removeLink: RemoveLink;
  removeMetric: RemoveMetric;
  removeMetricAggregatorManager: RemoveMetricAggregatorManager;
  removeMetricAggregatorManagerDep: RemoveMetricAggregatorManagerDep;
  removeOauthProvider: RemoveOauthProvider;
  removeServiceAccount: RemoveServiceAccount;
  removeStatus: RemoveStatus;
  removeStatusAggregatorManager: RemoveStatusAggregatorManager;
  removeStatusAggregatorManagerRule: RemoveStatusAggregatorManagerRule;
  removeStatusMetricManager: RemoveStatusMetricManager;
  updateLink: UpdateLink;
  updateOauthProvider: UpdateOauthProvider;
};

export type MutationApplyAgentArgs = {
  displayName?: InputMaybe<Scalars["String"]>;
  identifier: Scalars["String"];
  serviceAccount?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type MutationApplyAgentMetricProviderArgs = {
  agent: Scalars["String"];
  identifier: Scalars["String"];
  interval?: InputMaybe<Scalars["Float"]>;
  metric: Scalars["String"];
  metricName: Scalars["String"];
  nTries?: InputMaybe<Scalars["Int"]>;
};

export type MutationApplyAgentStatusProviderArgs = {
  agent: Scalars["String"];
  identifier: Scalars["String"];
  interval?: InputMaybe<Scalars["Float"]>;
  nTries?: InputMaybe<Scalars["Int"]>;
  status: Scalars["String"];
  statusName: Scalars["String"];
};

export type MutationApplyCertificateStatusProviderArgs = {
  host: Scalars["String"];
  identifier: Scalars["String"];
  interval?: InputMaybe<Scalars["Float"]>;
  minRemainingDays?: InputMaybe<Scalars["Int"]>;
  nTries?: InputMaybe<Scalars["Int"]>;
  remainingDaysMetric?: InputMaybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type MutationApplyEndpointStatusProviderArgs = {
  allow401?: InputMaybe<Scalars["Boolean"]>;
  allow403?: InputMaybe<Scalars["Boolean"]>;
  codeExpectEnd?: InputMaybe<Scalars["Int"]>;
  codeExpectStart?: InputMaybe<Scalars["Int"]>;
  headers?: InputMaybe<Scalars["String"]>;
  identifier: Scalars["String"];
  interval?: InputMaybe<Scalars["Float"]>;
  maxResponseTime?: InputMaybe<Scalars["Float"]>;
  method?: InputMaybe<EndpointStatusProviderMethodEnum>;
  nTries?: InputMaybe<Scalars["Int"]>;
  responseTimeMetric?: InputMaybe<Scalars["String"]>;
  status: Scalars["String"];
  url: Scalars["String"];
};

export type MutationApplyEntityArgs = {
  agent?: InputMaybe<Scalars["String"]>;
  alerting?: InputMaybe<Scalars["Boolean"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  identifier: Scalars["String"];
  label?: InputMaybe<Scalars["String"]>;
};

export type MutationApplyKubeAgentArgs = {
  agent: Scalars["String"];
  alerting?: InputMaybe<Scalars["Boolean"]>;
  backupAllowedDowntime?: InputMaybe<Scalars["Int"]>;
  backups: Scalars["Boolean"];
  backupsMaxAge?: InputMaybe<Scalars["Int"]>;
  certificateMinRemainingDays?: InputMaybe<Scalars["Int"]>;
  diskSpace: Scalars["Boolean"];
  diskSpaceMaxUsedSpace?: InputMaybe<Scalars["Float"]>;
  endpointAllowedDowntime?: InputMaybe<Scalars["Int"]>;
  endpoints: Scalars["Boolean"];
  excludeNamespaces?: InputMaybe<Array<Scalars["String"]>>;
  identifier: Scalars["String"];
  rancherPrefix?: InputMaybe<Scalars["String"]>;
};

export type MutationApplyLinkArgs = {
  entity: Scalars["String"];
  identifier: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type MutationApplyMetricArgs = {
  display?: InputMaybe<MetricDisplayEnum>;
  displayName?: InputMaybe<Scalars["String"]>;
  entity: Scalars["String"];
  identifier: Scalars["String"];
};

export type MutationApplyMetricAggregatorManagerArgs = {
  expression: Scalars["String"];
  identifier: Scalars["String"];
  metric: Scalars["String"];
};

export type MutationApplyMetricAggregatorManagerDepArgs = {
  as: Scalars["String"];
  identifier: Scalars["String"];
  manager: Scalars["String"];
  metric: Scalars["String"];
};

export type MutationApplyOauthProviderArgs = {
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  identifier: Scalars["String"];
  name: Scalars["String"];
  oauthUrl: Scalars["String"];
  redirectUri: Scalars["String"];
  scope: Scalars["String"];
};

export type MutationApplyServiceAccountArgs = {
  identifier: Scalars["String"];
  roles: Array<Scalars["String"]>;
};

export type MutationApplyStatusArgs = {
  entity: Scalars["String"];
  history?: InputMaybe<Scalars["Boolean"]>;
  identifier: Scalars["String"];
  threads?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationApplyStatusAggregatorManagerArgs = {
  allowedDowntime?: InputMaybe<Scalars["Int"]>;
  identifier: Scalars["String"];
  status: Scalars["String"];
};

export type MutationApplyStatusAggregatorManagerRuleArgs = {
  identifier: Scalars["String"];
  manager: Scalars["String"];
  ruleDegradedPerformance?: InputMaybe<StatusAggregatorManagerRuleRuleDegradedPerformanceEnum>;
  ruleMaintenance?: InputMaybe<StatusAggregatorManagerRuleRuleMaintenanceEnum>;
  ruleMajorOutage?: InputMaybe<StatusAggregatorManagerRuleRuleMajorOutageEnum>;
  rulePartialOutage?: InputMaybe<StatusAggregatorManagerRuleRulePartialOutageEnum>;
  ruleWarning?: InputMaybe<StatusAggregatorManagerRuleRuleWarningEnum>;
  status: Scalars["String"];
};

export type MutationApplyStatusMetricManagerArgs = {
  identifier: Scalars["String"];
  metric: Scalars["String"];
  operator: StatusMetricManagerOperatorEnum;
  status: Scalars["String"];
  statusValue?: InputMaybe<StatusMetricManagerStatusValueEnum>;
  value: Scalars["String"];
};

export type MutationAuthTokenLoginServiceAccountArgs = {
  authToken: Scalars["String"];
  identifier: Scalars["String"];
};

export type MutationCreateLinkArgs = {
  entityId: Scalars["ID"];
  id?: InputMaybe<Scalars["ID"]>;
  identifier: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type MutationCreateOauthProviderArgs = {
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  identifier: Scalars["String"];
  name: Scalars["String"];
  oauthUrl: Scalars["String"];
  redirectUri: Scalars["String"];
  scope: Scalars["String"];
};

export type MutationDeleteLinkArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteOauthProviderArgs = {
  id: Scalars["ID"];
};

export type MutationHtmlRenderEntityArgs = {
  dateMax: Scalars["DateTime"];
  dateMin: Scalars["DateTime"];
  identifier: Scalars["String"];
  intervalUnit: EntityHtmlRenderInputIntervalUnitEnum;
  tickInterval: Scalars["Int"];
};

export type MutationLoginOauthProviderArgs = {
  code: Scalars["String"];
  id: Scalars["ID"];
};

export type MutationRemoveAgentArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveAgentMetricProviderArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveAgentStatusProviderArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveCertificateStatusProviderArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveEndpointStatusProviderArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveEntityArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveKubeAgentArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveLinkArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveMetricArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveMetricAggregatorManagerArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveMetricAggregatorManagerDepArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveOauthProviderArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveServiceAccountArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveStatusArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveStatusAggregatorManagerArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveStatusAggregatorManagerRuleArgs = {
  identifier: Scalars["String"];
};

export type MutationRemoveStatusMetricManagerArgs = {
  identifier: Scalars["String"];
};

export type MutationUpdateLinkArgs = {
  entityId?: InputMaybe<Scalars["ID"]>;
  id: Scalars["ID"];
  identifier?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateOauthProviderArgs = {
  clientId?: InputMaybe<Scalars["String"]>;
  clientSecret?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  identifier?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  oauthUrl?: InputMaybe<Scalars["String"]>;
  redirectUri?: InputMaybe<Scalars["String"]>;
  scope?: InputMaybe<Scalars["String"]>;
};

export type OauthProvider = {
  __typename?: "OauthProvider";
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  name: Scalars["String"];
  oauthUrl: Scalars["String"];
  redirectUri: Scalars["String"];
  scope: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  findAgent: Array<Agent>;
  findAgentMetricProvider: Array<AgentMetricProvider>;
  findAgentStatusProvider: Array<AgentStatusProvider>;
  findCertificateStatusProvider: Array<CertificateStatusProvider>;
  findEndpointStatusProvider: Array<EndpointStatusProvider>;
  findEntity: Array<Entity>;
  findEvent: Array<Event>;
  findKubeAgent: Array<KubeAgent>;
  findLabel: Array<Label>;
  findLink: Array<Link>;
  findMetric: Array<Metric>;
  findMetricAggregatorManager: Array<MetricAggregatorManager>;
  findMetricAggregatorManagerDep: Array<MetricAggregatorManagerDep>;
  findOauthProvider: Array<OauthProvider>;
  findServiceAccount: Array<ServiceAccount>;
  findStatus: Array<Status>;
  findStatusAggregatorManager: Array<StatusAggregatorManager>;
  findStatusAggregatorManagerRule: Array<StatusAggregatorManagerRule>;
  findStatusMetricManager: Array<StatusMetricManager>;
  findThread: Array<Thread>;
  getEntity?: Maybe<Entity>;
  getKubeAgent?: Maybe<KubeAgent>;
  getLink?: Maybe<Link>;
  getOauthProvider?: Maybe<OauthProvider>;
  getThread?: Maybe<Thread>;
};

export type QueryFindAgentArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindAgentMetricProviderArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindAgentStatusProviderArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindCertificateStatusProviderArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindEndpointStatusProviderArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindEntityArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindEventArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindKubeAgentArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindLabelArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindLinkArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindMetricArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindMetricAggregatorManagerArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindMetricAggregatorManagerDepArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindOauthProviderArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindServiceAccountArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindStatusArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindStatusAggregatorManagerArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindStatusAggregatorManagerRuleArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindStatusMetricManagerArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryFindThreadArgs = {
  filter?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["String"]>;
};

export type QueryGetEntityArgs = {
  id: Scalars["ID"];
};

export type QueryGetKubeAgentArgs = {
  id: Scalars["ID"];
};

export type QueryGetLinkArgs = {
  id: Scalars["ID"];
};

export type QueryGetOauthProviderArgs = {
  id: Scalars["ID"];
};

export type QueryGetThreadArgs = {
  id: Scalars["ID"];
};

export type RemoveAgent = {
  __typename?: "RemoveAgent";
  _updates: Scalars["String"];
};

export type RemoveAgentMetricProvider = {
  __typename?: "RemoveAgentMetricProvider";
  _updates: Scalars["String"];
};

export type RemoveAgentStatusProvider = {
  __typename?: "RemoveAgentStatusProvider";
  _updates: Scalars["String"];
};

export type RemoveCertificateStatusProvider = {
  __typename?: "RemoveCertificateStatusProvider";
  _updates: Scalars["String"];
};

export type RemoveEndpointStatusProvider = {
  __typename?: "RemoveEndpointStatusProvider";
  _updates: Scalars["String"];
};

export type RemoveEntity = {
  __typename?: "RemoveEntity";
  _updates: Scalars["String"];
};

export type RemoveKubeAgent = {
  __typename?: "RemoveKubeAgent";
  _updates: Scalars["String"];
};

export type RemoveLink = {
  __typename?: "RemoveLink";
  _updates: Scalars["String"];
};

export type RemoveMetric = {
  __typename?: "RemoveMetric";
  _updates: Scalars["String"];
};

export type RemoveMetricAggregatorManager = {
  __typename?: "RemoveMetricAggregatorManager";
  _updates: Scalars["String"];
};

export type RemoveMetricAggregatorManagerDep = {
  __typename?: "RemoveMetricAggregatorManagerDep";
  _updates: Scalars["String"];
};

export type RemoveOauthProvider = {
  __typename?: "RemoveOauthProvider";
  _updates: Scalars["String"];
};

export type RemoveServiceAccount = {
  __typename?: "RemoveServiceAccount";
  _updates: Scalars["String"];
};

export type RemoveStatus = {
  __typename?: "RemoveStatus";
  _updates: Scalars["String"];
};

export type RemoveStatusAggregatorManager = {
  __typename?: "RemoveStatusAggregatorManager";
  _updates: Scalars["String"];
};

export type RemoveStatusAggregatorManagerRule = {
  __typename?: "RemoveStatusAggregatorManagerRule";
  _updates: Scalars["String"];
};

export type RemoveStatusMetricManager = {
  __typename?: "RemoveStatusMetricManager";
  _updates: Scalars["String"];
};

export type ServiceAccount = {
  __typename?: "ServiceAccount";
  authToken: Scalars["String"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  roles: Array<Scalars["String"]>;
};

export type Status = {
  __typename?: "Status";
  currentThread?: Maybe<Thread>;
  currentThreadId?: Maybe<Scalars["ID"]>;
  entity: Entity;
  entityId: Scalars["ID"];
  history: Scalars["Boolean"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  management: StatusManagementEnum;
  status: StatusStatusEnum;
  statusDate?: Maybe<Scalars["DateTime"]>;
  threads: Scalars["Boolean"];
};

export type StatusAggregatorManager = {
  __typename?: "StatusAggregatorManager";
  allowedDowntime: Scalars["Int"];
  id: Scalars["ID"];
  identifier: Scalars["String"];
  rules: Array<StatusAggregatorManagerRule>;
  status: Status;
  statusId: Scalars["ID"];
};

export type StatusAggregatorManagerRule = {
  __typename?: "StatusAggregatorManagerRule";
  id: Scalars["ID"];
  identifier: Scalars["String"];
  manager: StatusAggregatorManager;
  managerId: Scalars["ID"];
  ruleDegradedPerformance: StatusAggregatorManagerRuleRuleDegradedPerformanceEnum;
  ruleMaintenance: StatusAggregatorManagerRuleRuleMaintenanceEnum;
  ruleMajorOutage: StatusAggregatorManagerRuleRuleMajorOutageEnum;
  rulePartialOutage: StatusAggregatorManagerRuleRulePartialOutageEnum;
  ruleWarning: StatusAggregatorManagerRuleRuleWarningEnum;
  status: Status;
  statusId: Scalars["ID"];
};

export enum StatusAggregatorManagerRuleRuleDegradedPerformanceEnum {
  Degradedperformance = "DEGRADEDPERFORMANCE",
  Maintenance = "MAINTENANCE",
  Majoroutage = "MAJOROUTAGE",
  Operational = "OPERATIONAL",
  Partialoutage = "PARTIALOUTAGE",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
}

export enum StatusAggregatorManagerRuleRuleMaintenanceEnum {
  Degradedperformance = "DEGRADEDPERFORMANCE",
  Maintenance = "MAINTENANCE",
  Majoroutage = "MAJOROUTAGE",
  Operational = "OPERATIONAL",
  Partialoutage = "PARTIALOUTAGE",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
}

export enum StatusAggregatorManagerRuleRuleMajorOutageEnum {
  Degradedperformance = "DEGRADEDPERFORMANCE",
  Maintenance = "MAINTENANCE",
  Majoroutage = "MAJOROUTAGE",
  Operational = "OPERATIONAL",
  Partialoutage = "PARTIALOUTAGE",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
}

export enum StatusAggregatorManagerRuleRulePartialOutageEnum {
  Degradedperformance = "DEGRADEDPERFORMANCE",
  Maintenance = "MAINTENANCE",
  Majoroutage = "MAJOROUTAGE",
  Operational = "OPERATIONAL",
  Partialoutage = "PARTIALOUTAGE",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
}

export enum StatusAggregatorManagerRuleRuleWarningEnum {
  Degradedperformance = "DEGRADEDPERFORMANCE",
  Maintenance = "MAINTENANCE",
  Majoroutage = "MAJOROUTAGE",
  Operational = "OPERATIONAL",
  Partialoutage = "PARTIALOUTAGE",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
}

export enum StatusManagementEnum {
  Agentstatusprovider = "AGENTSTATUSPROVIDER",
  Aggregatormanager = "AGGREGATORMANAGER",
  Certificateprovider = "CERTIFICATEPROVIDER",
  Endpointprovider = "ENDPOINTPROVIDER",
  Free = "FREE",
  Metricmanager = "METRICMANAGER",
}

export type StatusMetricManager = {
  __typename?: "StatusMetricManager";
  id: Scalars["ID"];
  identifier: Scalars["String"];
  metric: Metric;
  metricId: Scalars["ID"];
  operator: StatusMetricManagerOperatorEnum;
  status: Status;
  statusId: Scalars["ID"];
  statusValue: StatusMetricManagerStatusValueEnum;
  value: Scalars["String"];
};

export enum StatusMetricManagerOperatorEnum {
  Eq = "EQ",
  Gt = "GT",
  Gte = "GTE",
  Lt = "LT",
  Lte = "LTE",
  Neq = "NEQ",
}

export enum StatusMetricManagerStatusValueEnum {
  Degradedperformance = "DEGRADEDPERFORMANCE",
  Maintenance = "MAINTENANCE",
  Majoroutage = "MAJOROUTAGE",
  Operational = "OPERATIONAL",
  Partialoutage = "PARTIALOUTAGE",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
}

export enum StatusStatusEnum {
  Degradedperformance = "DEGRADEDPERFORMANCE",
  Maintenance = "MAINTENANCE",
  Majoroutage = "MAJOROUTAGE",
  Operational = "OPERATIONAL",
  Partialoutage = "PARTIALOUTAGE",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
}

export type Thread = {
  __typename?: "Thread";
  entity: Entity;
  entityId: Scalars["ID"];
  id: Scalars["ID"];
  resolved: Scalars["Boolean"];
  threadItems: Array<ThreadItem>;
};

export type ThreadItem = {
  __typename?: "ThreadItem";
  comment?: Maybe<Scalars["String"]>;
  event: Event;
  eventId: Scalars["ID"];
  id: Scalars["ID"];
  resolve: Scalars["Boolean"];
  thread: Thread;
  threadId: Scalars["ID"];
};

export type UpdateLink = {
  __typename?: "UpdateLink";
  _updates: Scalars["String"];
};

export type UpdateOauthProvider = {
  __typename?: "UpdateOauthProvider";
  _updates: Scalars["String"];
};
