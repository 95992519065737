import React from 'react';

import { StatusBarData } from "@statusgate/common";
import { getBoundaryDates } from "../../helpers/getBoundaryDates";
import { BarDisplayInfos } from "./StatusBarTypes";
import StatusBar from "./StatusBar";

export interface StatusBarRenderProps {
  display: BarDisplayInfos;
  data: StatusBarData;
}

const StatusBarRender: React.FC<StatusBarRenderProps> = ({ data, display }) => {

  const { min, max } = getBoundaryDates();
  return (
    <StatusBar
      title={data.name}
      identifier={data.identifier}
      hidden={data.hidden}
      componentName={data.name}
      events={data.events}
      currentStatus={data.currentStatus}
      currentStatusDate={data.currentStatusDate}
      min={min}
      max={max}
      display={display}
    />
  );
};

export default StatusBarRender;