import { css, cx } from '@emotion/css';
import React from 'react';
import { EventStatuses, colorScheme } from '@statusgate/common';
import { Size, SizeParams, sizesParams, tickArrowHalfWidth, tickArrowHeight, tickArrowMarginTop } from '../StatusBarSizes';

const tickClass = css`
  border-radius: 3px;
  display: inline-block;
  border: 1px solid #ffffff;
  position: relative;
`;

const decorationClasses = {
  space: css`
    margin-bottom: calc(${tickArrowHeight}px + ${tickArrowMarginTop}px);
  `,
  arrow: css`
    &:before {
      width: 0;
      height: 0;
      border-left: ${tickArrowHalfWidth}px solid transparent;
      border-right: ${tickArrowHalfWidth}px solid transparent;
      border-bottom: ${tickArrowHeight}px solid #aaaaaa;
      content: ' ';
      position: absolute;
      top: calc(100% + ${tickArrowMarginTop}px);
      left: calc(-${tickArrowHalfWidth}px + 50%);
    }

    &:after {
      width: 0;
      height: 0;
      border-left: ${tickArrowHalfWidth}px solid transparent;
      border-right: ${tickArrowHalfWidth}px solid transparent;
      border-bottom: ${tickArrowHeight}px solid #ffffff;
      content: ' ';
      position: absolute;
      top: calc(100% + ${tickArrowMarginTop}px + 1px);
      left: calc(-${tickArrowHalfWidth}px + 50%);
      z-index: 100;
    }
  `,
};

const tickSizeClasses = (Object.entries(sizesParams) as [Size, SizeParams][]).reduce<Partial<Record<Size, string>>>((tickSizeClasses, [size, sizeParams]) => {
  tickSizeClasses[size] = css`
    height: ${sizeParams.tickHeight}px;
    width: ${sizeParams.tickWidth}px;
  `;

  return tickSizeClasses;
}, {}) as Record<Size, string>;

const tickClickableClass = css`
  cursor: pointer;
`;

const colorClasses = (Object.entries(colorScheme) as [EventStatuses, string][]).reduce<Partial<Record<EventStatuses, string>>>((colorClasses, [status, color]) => {
  colorClasses[status] = css`background-color: ${color};`;
  return colorClasses;
}, {}) as Record<EventStatuses, string>;

export interface StatusBarTickProps {
  status: EventStatuses;
  color: string | null;
  size: Size;
  decorations?: ('arrow' | 'space')[];
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onHover?: (hover: boolean, target: HTMLDivElement) => void;
}

const StatusBarTick: React.FC<StatusBarTickProps> = ({ size, status, color, decorations=[], onClick, onHover }) => {
  return (
    <div
      className={cx(
        tickClass,
        tickSizeClasses[size],
        color === null ? colorClasses[status] : css`background-color: ${color};`,
        ...decorations.map(decoration => decorationClasses[decoration]),
        onClick !== undefined && tickClickableClass,
      )}
      onClick={onClick}
      onMouseEnter={onHover === undefined ? undefined : (event) => {
        onHover(true, event.target as HTMLDivElement);
      }}
      onMouseLeave={onHover === undefined ? undefined : (event) => {
        onHover(false, event.target as HTMLDivElement);
      }}
    />
  );
};

export default StatusBarTick;
