import React from 'react';
import { CircularProgress } from '@mui/material';
import { Centered } from '@guy/flex-flow';

interface LogoutPageProps {
}

const LogoutPage: React.FC<LogoutPageProps> = () => {

  window.localStorage.removeItem('token');

  window.location.href = '/';

  return <Centered>
    <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
  </Centered>;
};

export default LogoutPage;
