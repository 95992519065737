import { clamp, discretize, invLerp, lerp } from "../helpers/math";

const hue2rgb = (p: number, q: number, t: number) => {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1/6) return p + (q - p) * 6 * t;
  if (t < 1/2) return q;
  if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
  return p;
};

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
export const hslToRgb = ([h, s, l]: readonly [number, number, number]): [number, number, number] => {
  let r, g, b;

  if (s == 0) {
    r = g = b = l; // achromatic
  }
  else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   {number}  r       The red color value
 * @param   {number}  g       The green color value
 * @param   {number}  b       The blue color value
 * @return  {Array}           The HSL representation
 */
export const rgbToHsl = ([r, g, b]: readonly [number, number, number]): [number, number, number] => {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = 0;
  let s;
  let l = (max + min) * 0.5;

  if (max == min) {
    s = 0; // achromatic
  }
  else {
    const d = max - min;

    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }

    h /= 6;
  }

  return [h, s, l];
};

const hexColorRegex = /^#?(?<r>[0-9a-fA-F]{2})(?<g>[0-9a-fA-F]{2})(?<b>[0-9a-fA-F]{2})$/;

export const parseHexColor = (hexColor: string): [number, number, number] => {
  const match = hexColorRegex.exec(hexColor);

  if (match === null) {
    return [0, 0, 0];
  }

  const groups = match.groups as { r: string, g: string, b: string };

  const r = parseInt(`0x${groups.r}`, 16);
  const g = parseInt(`0x${groups.g}`, 16);
  const b = parseInt(`0x${groups.b}`, 16);

  return [r, g, b];
};

export const stringifyHexColor = ([r, g, b]: readonly [number, number, number]): string => {
  r = discretize(r, 0, 255);
  g = discretize(g, 0, 255);
  b = discretize(b, 0, 255);

  const rStr = r.toString(16).padStart(2, '0');
  const gStr = g.toString(16).padStart(2, '0');
  const bStr = b.toString(16).padStart(2, '0');

  return `#${rStr}${gStr}${bStr}`;
};

export type MergeColorsCheckpoint = { t: number, color: string };

export const mergeColors = (a: string, b: string, t: number, checkPoints?: MergeColorsCheckpoint[]): string => {
  t = clamp(t, 0, 1);

  if (t === 0) {
    return a;
  }

  if (t === 1) {
    return b;
  }

  if (checkPoints !== undefined) {
    checkPoints = [{ t: 0, color: a }, ...checkPoints, { t: 1, color: b }].sort((a, b) => {
      return a.t - b.t;
    });

    for (let i = 1; i < checkPoints.length; ++i) {
      const stepA = checkPoints[i - 1];
      const stepB = checkPoints[i];

      if (t <= stepB.t) {
        const newT = invLerp(stepA.t, stepB.t, t);

        return mergeColors(stepA.color, stepB.color, newT);
      }
    }
  }

  const aRGB = parseHexColor(a);
  const bRGB = parseHexColor(b);

  const aHSL = rgbToHsl(aRGB);
  const bHSL = rgbToHsl(bRGB);

  const nHSL = [
    lerp(aHSL[0], bHSL[0], t),
    lerp(aHSL[1], bHSL[1], t),
    lerp(aHSL[2], bHSL[2], t),
  ] as const;

  const nRGB = hslToRgb(nHSL);

  return stringifyHexColor(nRGB);
};
