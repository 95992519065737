import React, { useState } from 'react';
import { panelClass } from '../../style/panelClass';
import { css, cx } from '@emotion/css';
import { BarDisplayInfos } from '../StatusBar/StatusBarTypes';
import StatusBar from '../StatusBar/StatusBarRender';
import { EventStatuses } from '@statusgate/common';
import { MetricDisplayEnum, StatusManagementEnum } from '../../bold/types';
import { FindEntityQuery } from '../../bold/gqlTypes';
import { OneOf, renderOutOfDateInfos } from '@statusgate/common';
import { CircularProgress, ButtonGroup, Button } from '@mui/material';
import { Centered } from '@guy/flex-flow';
import { sizesParams } from '../StatusBar/StatusBarSizes';
import { useEventsWithThreadItems } from '../../hooks/useEvents';
import { useEntityDependencyEntities } from '../../hooks/useEntityDependencies';
import { styled } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


export const barDisplayBig: BarDisplayInfos = {
  size: 'big',
  tickInterval: 1,
  intervalUnit: 'days',
  listEvents: false,
  rightDecoration: 'status',
  subBar: {
    size: 'small',
    tickInterval: 15,
    intervalUnit: 'minutes',
    listEvents: true,
    rightDecoration: 'close',
    subBar: {
      size: 'small',
      intervalUnit: 'minutes',
      listEvents: false,
    },
  },
};

export const barDisplayNormal: BarDisplayInfos = {
  size: 'normal',
  tickInterval: 1,
  intervalUnit: 'days',
  listEvents: false,
  rightDecoration: 'status',
  subBar: {
    size: 'small',
    tickInterval: 15,
    intervalUnit: 'minutes',
    listEvents: true,
    rightDecoration: 'close',
    subBar: {
      size: 'small',
      intervalUnit: 'minutes',
      listEvents: false,
    },
  },
};



const groupClass = css`
  margin: 10px;
`;

const smallClass = css`
  font-size: 0.875em;
`;

const listClass = css`
  margin: 1em auto;
  text-align: center;
`;

const titleClass = css`
  margin-bottom: 0.5em;
  font-size: 1em;
`;

const metricTitleBlockClass = css`
  margin-top: 0.25em;
`;

const metricTitleSpanClass = css`
  font-weight: bold;
`;

const badgeClass = css`
  display: inline-block;
  background-color: #eeeeee;
  border-radius: 3px;
  padding: 0.5em;
  margin-right: 0.5em;
`;

const showDependenciesClass = css`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const LinkButton = styled(Button)({
  textTransform: "none",
});

const metricDisplayMap: Record<MetricDisplayEnum, (value: number) => string> = {
  [MetricDisplayEnum.Date]: (value) => (new Date(value)).toLocaleString(),
  [MetricDisplayEnum.Number]: (value) => value.toString(),
  [MetricDisplayEnum.Percent]: (value) => `${(value * 100).toFixed(1)}%`,
  [MetricDisplayEnum.Volumesize]: (value) => `${(value / 1024).toFixed(2)}GB`,
  [MetricDisplayEnum.Seconds]: (value) => `${value.toFixed(2)}s`,
};


const entityHasDependencies = (entity: OneOf<FindEntityQuery['findEntity']>) => {

  for (const status of entity.status) {
    if (status.management === StatusManagementEnum.Aggregatormanager/* || status.management === StatusManagementEnum.Metricmanager*/) {
      return true;
    }
  }

  // for (const metric of entity.metrics) {
  //   if (metric.management === MetricManagementEnum.Aggregatormanager) {
  //     return true;
  //   }
  // }

  return false;
};


export interface ListItemProps {
  entity: OneOf<FindEntityQuery['findEntity']>;
  barDisplay: BarDisplayInfos;
}

interface Badge {
  key: string;
  value: string;
}

export const ListItem: React.FC<ListItemProps> = ({ entity, barDisplay }) => {
  const [dependenciesExpanded, setDependenciesExpanded] = useState(false);

  const size = barDisplay.size || 'normal';

  // const { events, error, loading } = useBaseEvents(entity.id);
  const { events: foundEvents } = useEventsWithThreadItems(entity);

  const events = foundEvents ?? [];

  const badges: Badge[] = [];

  if (entity.agent !== undefined && entity.agent !== null) {
    badges.push({
      key: 'Agent',
      value: entity.agent.displayName ?? entity.agent.identifier,
    });
  }

  if (entity.label !== undefined && entity.label !== null) {
    badges.push({
      key: 'Label',
      value: entity.label,
    });
  }

  if (entity.alerting === true) {
    badges.push({
      key: 'Alerting',
      value: 'True',
    });
  }

  if (entity.hidden) {
    badges.push({
      key: 'Hidden',
      value: 'True',
    });
  }

  return (
    <div className={listClass}>
      <div className={panelClass} style={{ display: 'inline-block', textAlign: 'left' }}>
        {
          entity.status.length === 0 && (
            <div className={titleClass}>
              {entity.displayName ?? entity.identifier}
            </div>
          )
        }
        {
          entity.status.length === 1 && (
            <StatusBar data={{
              name: entity.displayName ?? entity.identifier,
              identifier: entity.identifier,
              hidden: entity.hidden,
              currentStatus: entity.status[0].status as unknown as EventStatuses,
              currentStatusDate: entity.status[0].statusDate,
              events,
            }} display={barDisplay} />
          )
        }
        {
          entity.links &&
          <div className={cx(groupClass, smallClass)}>
            <ButtonGroup size="small" style={{ marginRight: '5px' }}>
              {
                entity.links.map((link) => <LinkButton key={link.id} endIcon={<OpenInNewIcon />} onClick={() => window.open(link.url)}>{link.name}</LinkButton>)
              }
            </ButtonGroup>
          </div>
        }

        {
          badges.length > 0 && (
            <div className={cx(groupClass, smallClass)}>
              {
                badges.map(badge => (
                  <div key={badge.key} className={badgeClass}>
                    {badge.key}: {badge.value}
                  </div>
                ))
              }

            </div>
          )
        }
        {
          entity.metrics.length > 0 && (
            <div className={cx(groupClass, smallClass)}>
              {
                entity.metrics.map(metric => (
                  <div key={metric.id}>
                    <div className={metricTitleBlockClass}>
                      <span className={metricTitleSpanClass}>
                        {metric.displayName ?? metric.identifier}
                      </span>
                      <span className={sizesParams[size].subtitleClass}>
                        {renderOutOfDateInfos(metric.valueDate)}
                      </span>
                    </div>
                    {metric.value === null || metric.value === undefined ? '-' : metricDisplayMap[metric.display](metric.value)}
                    {/* {' '}
                    {metric.valueDate !== null && <>({(metric.valueDate as Date).toLocaleString()})</>} */}
                  </div>
                ))
              }
            </div>
          )
        }
        {
          entityHasDependencies(entity) && (
            <div className={groupClass}>
              <span className={cx(showDependenciesClass, smallClass)} onClick={() => setDependenciesExpanded(!dependenciesExpanded)}>
                {
                  dependenciesExpanded ? 'Hide dependencies' : 'Show dependencies'
                }
              </span>
              {
                dependenciesExpanded && (
                  <SubList entity={entity} />
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export interface ListProps {
  data: FindEntityQuery;
  barDisplay: BarDisplayInfos;
}

const List: React.FC<ListProps> = ({ data, barDisplay }) => {
  return <>
    {
      data.findEntity.map(entity => (
        <ListItem key={entity.id} entity={entity} barDisplay={barDisplay} />
      ))
    }
  </>;
};

interface SubListprops {
  entity: OneOf<FindEntityQuery['findEntity']>
}

const SubList: React.FC<SubListprops> = ({ entity }) => {

  const { data, error, loading } = useEntityDependencyEntities(entity, false);

  if (loading) {
    return <Centered>
      <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)', margin: '3em' }} />
    </Centered>;
  }

  if (!data || error) {
    return <code>{JSON.stringify(error, null, 2)}</code>;
  }

  return <List data={data} barDisplay={barDisplayNormal} />;
};

export default List;
