export const getBoundaryDates = () => {
  const max = new Date();
  max.setHours(0, 0, 0, 0);
  max.setDate(max.getDate() + 1);
  
  const min = new Date(max);
  min.setDate(min.getDate() - 90);

  return {
    min,
    max,
  };
};