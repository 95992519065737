import { css, cx } from '@emotion/css';
import React from 'react';
import { StatusEvent, colorScheme, renderOutOfDateInfos, statusDisplay, StatusBarLink } from '@statusgate/common';
import { barPaddingOffset, Size, sizesParams, toolTipDefaultWidth } from './StatusBarSizes';
import StatusBarTicks from './StatusBarTicks/StatusBarTicks';
import StatusBarLegend from './StatusBarLegend/StatusBarLegend';
import StatusBarTooltip from './StatusBarTooltip/StatusBarTooltip';
import { useState } from 'react';
import { BarTickData, BarDisplayInfos } from './StatusBarTypes';
import { useEffect } from 'react';
import { debounce, EventStatuses } from '@statusgate/common';
import { Flow, Item } from '@guy/flex-flow';
import { useMemo } from 'react';
import StatusBarEventList from './StatusBarEventList/StatusBarEventList';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { styled } from '@mui/system';
import DeleteOutlinedIcon from '@mui/icons-material/Delete';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import ClosedEyeIcon from '@mui/icons-material/VisibilityOff';
import { ButtonGroup, Dialog, IconButton } from '@mui/material';
import { useApplyEntityMutation, useRemoveEntityMutation } from '../../bold/gql';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography/Typography';

const wrapperClass = css`
  position: relative;
  padding: ${barPaddingOffset}px;
`;

const closeIconClass = (size: number) => css`
  position: absolute;
  right: 8px;
  top: 8px;
  width: ${size}px;
  height: ${size}px;
  cursor: pointer;
  transition: opacity 0.1s ease-out;
  opacity: 0.3;

  &:hover {
    opacity: 0.8;
  }

  &:before, &:after {
    position: absolute;
    left: ${size * 0.5 - 1}px;
    content: ' ';
    height: ${size + 1}px;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const noTicksClass = css`
  width: ${toolTipDefaultWidth}px;
`;

const titleLinkClass = css`
  text-decoration: underline;
  cursor: pointer;
  color: black;

  &:hover {
    text-decoration: none;
  }
`;

const LinkButton = styled(Button)({
  textTransform: "none",
});

export const colorClasses = (Object.entries(colorScheme) as [EventStatuses, string][]).reduce<Partial<Record<EventStatuses, string>>>((colorClasses, [status, color]) => {
  colorClasses[status] = css`color: ${color};`;
  return colorClasses;
}, {}) as Record<EventStatuses, string>;

interface StatusBarCurrentStatusProps {
  size: Size;
  currentStatus: EventStatuses;
  currentStatusDate: Date | null | undefined;
}

const StatusBarCurrentStatus: React.FC<StatusBarCurrentStatusProps> = ({ size, currentStatus, currentStatusDate }) => {

  return <>
    <span className={cx(sizesParams[size].titleClass, colorClasses[currentStatus])}>
      {statusDisplay[currentStatus]}
    </span>
    <span className={sizesParams[size].subtitleClass}>
      {renderOutOfDateInfos(currentStatusDate)}
    </span>
  </>;
};


export interface StatusBarProps {
  identifier?: string;
  title?: string;
  hidden?: boolean;
  componentName: string;
  events: StatusEvent[];
  currentStatus: EventStatuses;
  currentStatusDate: Date | null | undefined;
  min: Date;
  max: Date;
  onClose?: () => void;
  display: BarDisplayInfos;
}

const StatusBar: React.FC<StatusBarProps> = ({ 
  identifier, 
  title, 
  componentName, 
  events, 
  currentStatus, 
  currentStatusDate, 
  min, 
  max, 
  display, 
  onClose, 
  hidden,
}) => {

  const size = display.size || 'normal';

  const eventsWithVirtualUndefinedNow = useMemo(() => {
    const fork = [...events];

    fork.push({
      date: new Date(),
      status: EventStatuses.Undefined,
      nativeEvents: [],
    });

    return fork;
  }, [events]);

  const [hoveredTick, setHoveredTick] = useState<BarTickData | null>(null);
  const [tooltipTick, setTooltipTick] = useState<BarTickData | null>(null);
  const [tooltipHovered, setTooltipHovered] = useState<BarTickData | null>(null);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState<boolean>(false);

  const [ applyEntityMutation ] = useApplyEntityMutation();
  const [ removeEntityMutation ] = useRemoveEntityMutation();

  useEffect(() => {
    const tooltip = tooltipHovered !== null
      ? tooltipHovered
      : hoveredTick;
    
    if (tooltip !== null) {
      setTooltipTick(tooltip);
    }
    else {
      const cancel = debounce(() => {
        setTooltipTick(null);
      }, 500);
  
      return cancel;
    }
    
  }, [hoveredTick, tooltipHovered]);

  const toggleVisibility = async () => {
    if (identifier === undefined) {
      return;
    }

    try {
      await applyEntityMutation({
        variables: {
          identifier: identifier,
          hidden: !hidden
        }
      });
    } catch(error) {
      console.log(`[ERROR] <StatusBar.toggleVisibility> Couldn't toggle entity visibility`, error);
    }
  }

  const deleteEntity = async () => {
    if (identifier === undefined) {
      return;
    }

    try {
      await removeEntityMutation({
        variables: {
          identifier: identifier,
        }
      });
    } catch (error) {
      console.log(`[ERROR] <StatusBar.deleteEntity> Couldn't delete entity ${identifier}`, error);
    } finally {
      toggleDeleteDialogVisibility();
    }
  };

  const toggleDeleteDialogVisibility = () => {
    setDeleteDialogOpened(!deleteDialogOpened);
  };
  return (
    <div className={cx(wrapperClass, size)}>
      {
        (title !== undefined || !onClose) && (
          <Flow direction="row">
            <Item type="spreadable">
              {
                title !== undefined && (
                  <>
                    <div className={sizesParams[size].titleClass}>
                      {
                        identifier !== undefined ? (
                        <a className={titleLinkClass} href={`/entity/${encodeURIComponent(identifier)}`} target="_blank" rel="noreferrer">
                          {title}
                        </a>
                        ) : title
                      }
                    </div>
                  </>
                )
              }
              
              {
                identifier !== undefined && identifier !== title && (
                  <div className={sizesParams[size].subtitleClass}>
                    {identifier}
                  </div>
                )
              }
            </Item>
            {
              display.rightDecoration === 'status' && <Item type="fitted">
                <StatusBarCurrentStatus
                  size={size}
                  currentStatus={currentStatus}
                  currentStatusDate={currentStatusDate}
                />
                <IconButton color="primary" size="small" type='button' onClick={toggleVisibility}>
                  {
                    hidden !== undefined && (hidden ? (
                      <EyeIcon />
                    ) : (
                      <ClosedEyeIcon />
                    ))
                  }
                </IconButton>
                <IconButton color="error" size="small" type='button' onClick={toggleDeleteDialogVisibility}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </Item>
            }
          </Flow>
        )
      }
      {
        display.rightDecoration === 'close' && onClose && (
          <div className={closeIconClass(sizesParams[size].closeIconSize)} onClick={onClose} />
        )
      }
      {
        display.tickInterval !== undefined ? (
          <StatusBarTicks
            size={size}
            events={eventsWithVirtualUndefinedNow}
            min={min}
            max={max}
            interval={display.tickInterval}
            intervalUnit={display.intervalUnit}
            tooltipTick={tooltipTick}
            setHoveredTick={(hoveredTick: BarTickData | null) => {
              if (display.subBar !== undefined) {
                setHoveredTick(hoveredTick);
              }
            }}
          />
        ) : (
          <div className={noTicksClass} />
        )
      }
      <StatusBarLegend
        size={size}
        events={eventsWithVirtualUndefinedNow}
        min={min}
        max={max}
        intervalUnit={display.intervalUnit}
      />
      {
        display.listEvents && (
          <StatusBarEventList
            componentName={componentName}
            events={events}
            min={min}
            max={max}
          />
        )
      }
      {
        display.tickInterval !== undefined && tooltipTick !== null && (
          <StatusBarTooltip
            componentName={componentName}
            size={size}
            events={events}
            currentStatus={currentStatus}
            currentStatusDate={currentStatusDate}
            min={min}
            max={max}
            tickInterval={display.tickInterval}
            intervalUnit={display.intervalUnit}
            subBar={display.subBar}
            tooltipTick={tooltipTick}
            setHovered={(hovered) => setTooltipHovered(hovered ? tooltipTick : null)}
            onClose={() => {
              setTooltipHovered(null);
              setTooltipTick(null);
            }}
          />
        )
      }
      {
        display.rightDecoration === 'status' &&
          <Dialog open={deleteDialogOpened} onClose={() => setDeleteDialogOpened(false)}>
            <DialogTitle>Confirm entity deletion ?</DialogTitle>
            <DialogContent dividers>
              <Typography>Deleting an entity will remove all past events and linked data.</Typography>
            </DialogContent>
            <DialogActions>
              <Button color="error" variant="contained" startIcon={<DeleteOutlinedIcon />} onClick={deleteEntity}>Delete</Button>
              <Button variant="contained" onClick={toggleDeleteDialogVisibility}>Cancel</Button>
            </DialogActions>
          </Dialog>
      }
    </div>
  );
};

export default StatusBar;
