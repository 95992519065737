import React from 'react';
import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useLoginOauthProviderMutation } from '../../bold/gql';

interface OauthVerifyPageProps {
}

const OauthVerifyPage: React.FC<OauthVerifyPageProps> = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const [loginOauthProviderMutation] = useLoginOauthProviderMutation();
  const navigate = useNavigate();

  useEffect(() => {

    if (code === null || state === null) {
      navigate('/');
    }
    else {
      const providerId = window.sessionStorage.getItem(`oauth-state-${state}`);

      if (providerId === null) {
        navigate('/');
      }
      else {
        window.sessionStorage.removeItem(`oauth-state-${state}`);

        loginOauthProviderMutation({
          variables: {
            id: providerId,
            code,
          },
        }).then((result) => {
          const data = result.data;
      
          if (data !== undefined && data !== null) {
            localStorage.setItem('token', data.loginOauthProvider.boldToken);
      
            window.location.href = '/';
          }
        });
      }
    }

  }, [code, loginOauthProviderMutation, navigate, state]);

  return null;
};

export default OauthVerifyPage;
