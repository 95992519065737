import React, { useEffect, useState } from 'react';
import { useFindDrawerItemsQuery } from '../../bold/gql';
import Luq from '@luq/core';
import Layout from '../../layout/Layout';
import { DrawerItem, DrawerMenu, DrawerSubMenu } from '@guy/drawer-elements';
import CheckIcon from '@mui/icons-material/Check';
import { EventStatuses, debounce } from '@statusgate/common';
import { Centered } from '@guy/flex-flow';
import { statusDisplay } from '@statusgate/common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { parse, ParsedQuery, stringify } from 'query-string';
import { CircularProgress } from '@mui/material';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ListContent from '../../components/EntityList/ListContent';


export interface DrawerContentProps {
  params: ParsedQuery<string>;
  selectedLabels: Record<string, true>;
  selectedAgents: Record<string, true>;
  selectedStatuses: Partial<Record<EventStatuses, true>>;
  includeHidden: boolean;
}

const DrawerContent: React.FC<DrawerContentProps> = ({ params, selectedLabels, selectedAgents, selectedStatuses, includeHidden }) => {

  const navigate = useNavigate();  

  const setSelectedLabels = (selectedLabels: Record<string, true>) => {
    const labels = Object.keys(selectedLabels);

    navigate(`?${stringify({
      ...params,
      selectedLabels: labels,
    }, { arrayFormat: 'bracket' })}`);
  };

  const setSelectedAgents = (selectedAgents: Record<string, true>) => {
    const agents = Object.keys(selectedAgents);

    navigate(`?${stringify({
      ...params,
      selectedAgents: agents,
    }, { arrayFormat: 'bracket' })}`);
  };

  const setSelectedStatuses = (selectedStatuses: Partial<Record<EventStatuses, true>>) => {
    const statuses = Object.keys(selectedStatuses);

    navigate(`?${stringify({
      ...params,
      selectedStatuses: statuses,
    }, { arrayFormat: 'bracket' })}`);
  };

  const setIncludeHidden = (includeHidden: boolean) => {
    if (includeHidden) {
      navigate(`?${stringify({
        ...params,
        includeHidden: 'true',
      }, { arrayFormat: 'bracket' })}`);
    }
    else {
      const { includeHidden: _, ...rest } = params;

      navigate(`?${stringify({
        ...rest,
      }, { arrayFormat: 'bracket' })}`);
    }
  };

  const { data: drawerData, error: drawerError, loading: drawerLoading } = useFindDrawerItemsQuery({
    variables: {
      orderLabels: Luq(<Luq.Order>
        <Luq.Asc field="name" />
      </Luq.Order>),
      orderAgents: Luq(<Luq.Order>
        <Luq.Asc field="displayName" />
        <Luq.Asc field="identifier" />
      </Luq.Order>),
    },
  });

  if (drawerLoading) {
    return <Centered>
      <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </Centered>;
  }

  if (!drawerData || drawerError) {
    return <code>{JSON.stringify(drawerError, null, 2)}</code>;
  }

  return (
    <DrawerMenu>
      <DrawerSubMenu text="Statuses" initialOpen>
        {
          Object.values(EventStatuses).map(status => (
            <DrawerItem
              key={status}
              text={statusDisplay[status]}
              icon={status in selectedStatuses && <CheckIcon />}
              onClick={() => {
                if (status in selectedStatuses) {
                  const { [status]: _, ...rest } = selectedStatuses;
                  setSelectedStatuses(rest);
                }
                else {
                  setSelectedStatuses({ ...selectedStatuses, [status]: true });
                }
              }}
            />
          ))
        }
      </DrawerSubMenu>
      <DrawerSubMenu text="Labels" initialOpen>
        {
          drawerData.findLabel.map(label => (
            <DrawerItem
              key={label.id}
              text={label.name}
              icon={label.name in selectedLabels && <CheckIcon />}
              onClick={() => {
                if (label.name in selectedLabels) {
                  const { [label.name]: _, ...rest } = selectedLabels;
                  setSelectedLabels(rest);
                }
                else {
                  setSelectedLabels({ ...selectedLabels, [label.name]: true });
                }
              }}
            />
          ))
        }
      </DrawerSubMenu>
      <DrawerSubMenu text="Agents" initialOpen>
        {
          drawerData.findAgent.map(agent => (
            <DrawerItem
              key={agent.id}
              text={agent.displayName ?? agent.identifier}
              icon={agent.id in selectedAgents && <CheckIcon />}
              onClick={() => {
                if (agent.id in selectedAgents) {
                  const { [agent.id]: _, ...rest } = selectedAgents;
                  setSelectedAgents(rest);
                }
                else {
                  setSelectedAgents({ ...selectedAgents, [agent.id]: true });
                }
              }}
            />
          ))
        }
      </DrawerSubMenu>
      <DrawerSubMenu text="Other" initialOpen>
        <DrawerItem
          text="Include hidden"
          icon={includeHidden && <CheckIcon />}
          onClick={() => {
            setIncludeHidden(!includeHidden);
          }}
        />
      </DrawerSubMenu>
    </DrawerMenu>
  );
};

export interface SearchBarProps {
  params: ParsedQuery<string>;
  search: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ params, search }) => {

  const navigate = useNavigate();  

  const setSearch = (value: string) => {
    if (value === '') {
      const { search: _, ...rest } = params;

      navigate(`?${stringify({
        ...rest,
      }, { arrayFormat: 'bracket' })}`);
    }
    else {
      navigate(`?${stringify({
        ...params,
        search: value,
      }, { arrayFormat: 'bracket' })}`);
    }
  };

  const [searchValue, setSearchValue] = useState(search);

  useEffect(() => {
    return debounce(() => {
      setSearch(searchValue);
    }, 250);
  }, [searchValue]);

  return (
    <TextField
      size="small"
      placeholder="Search..."
      value={searchValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchValue !== '' && (
          <InputAdornment position="end">
            <IconButton
              aria-label="Dismiss"
              onClick={() => {
                setSearchValue('');
                setSearch('');
              }}
              edge="end"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export interface MainPageProps {
}

const MainPage: React.FC<MainPageProps> = () => {

  const [searchParams] = useSearchParams();
  const paramsStr = searchParams.toString();

  const params = parse(paramsStr, {
    arrayFormat: 'bracket',
  });

  params.selectedLabels = params.selectedLabels ?? [];
  params.selectedAgents = params.selectedAgents ?? [];
  params.selectedStatuses = params.selectedStatuses ?? [];
  params.search = params.search ?? '';

  const selectedLabels = (params.selectedLabels as string[]).reduce<Record<string, true>>((selectedLabels, label) => {
    selectedLabels[label] = true;
    return selectedLabels;
  }, {});

  const selectedAgents = (params.selectedAgents as string[]).reduce<Record<string, true>>((selectedAgents, agent) => {
    selectedAgents[agent] = true;
    return selectedAgents;
  }, {});

  const selectedStatuses = (params.selectedStatuses as EventStatuses[]).reduce<Partial<Record<EventStatuses, true>>>((selectedStatuses, status) => {
    selectedStatuses[status] = true;
    return selectedStatuses;
  }, {});

  const includeHidden = params.includeHidden === 'true';
  const search = params.search as string;
  
  const selectedLabelsKeys = Object.keys(selectedLabels);
  const selectedAgentsKeys = Object.keys(selectedAgents);
  const selectedStatusesKeys = Object.keys(selectedStatuses) as EventStatuses[];

  return (
    <Layout
      drawerContent={<DrawerContent
        params={params}
        selectedLabels={selectedLabels}
        selectedAgents={selectedAgents}
        selectedStatuses={selectedStatuses}
        includeHidden={includeHidden}
      />}
      navBarRight={<SearchBar
        params={params}
        search={search}
      />}
    >
      <ListContent
        selectedLabels={selectedLabelsKeys}
        selectedAgents={selectedAgentsKeys}
        selectedStatuses={selectedStatusesKeys}
        includeHidden={includeHidden}
        search={search}
      />
    </Layout>
  );
};

export default MainPage;
