import React from 'react';
import { EventStatuses } from '@statusgate/common';
import { useSearchParams } from 'react-router-dom';
import { parse } from 'query-string';
import ListContent from '../../components/EntityList/ListContent';


export interface ListPageProps {
}

const ListPage: React.FC<ListPageProps> = () => {

  const [searchParams] = useSearchParams();
  const paramsStr = searchParams.toString();

  const params = parse(paramsStr, {
    arrayFormat: 'bracket',
  });

  params.selectedLabels = params.selectedLabels ?? [];
  params.selectedAgents = params.selectedAgents ?? [];
  params.selectedStatuses = params.selectedStatuses ?? [];
  params.search = params.search ?? '';

  const selectedLabels = (params.selectedLabels as string[]).reduce<Record<string, true>>((selectedLabels, label) => {
    selectedLabels[label] = true;
    return selectedLabels;
  }, {});

  const selectedAgents = (params.selectedAgents as string[]).reduce<Record<string, true>>((selectedAgents, agent) => {
    selectedAgents[agent] = true;
    return selectedAgents;
  }, {});

  const selectedStatuses = (params.selectedStatuses as EventStatuses[]).reduce<Partial<Record<EventStatuses, true>>>((selectedStatuses, status) => {
    selectedStatuses[status] = true;
    return selectedStatuses;
  }, {});

  const includeHidden = params.includeHidden === 'true';
  const search = params.search as string;
  
  const selectedLabelsKeys = Object.keys(selectedLabels);
  const selectedAgentsKeys = Object.keys(selectedAgents);
  const selectedStatusesKeys = Object.keys(selectedStatuses) as EventStatuses[];

  return (
    <ListContent
      selectedLabels={selectedLabelsKeys}
      selectedAgents={selectedAgentsKeys}
      selectedStatuses={selectedStatusesKeys}
      includeHidden={includeHidden}
      search={search}
    />
  );
};

export default ListPage;
