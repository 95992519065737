import React from 'react';
import { Link } from "react-router-dom";
import { useFindOauthProviderQuery } from '../../bold/gql';
import { panelClass } from '../../style/panelClass';
import { Centered } from '@guy/flex-flow';
import { css, cx } from '@emotion/css';
import { CircularProgress } from '@mui/material';

interface LoginPageProps {
}

const loginClass = css`
  padding: 3em;
  padding-top: 2em;
  margin-top: -5em;
`;

const LoginPage: React.FC<LoginPageProps> = () => {

  const { data, loading } = useFindOauthProviderQuery();

  if (loading) {
    return <Centered>
      <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </Centered>;
  }

  const oauthProviders = data?.findOauthProvider ?? [];

  return (
    <Centered>
      <div className={cx(panelClass, loginClass)}>
        <h1>Login using provider :</h1>
        {
          oauthProviders.map(oauthProvider => (
            <Link key={oauthProvider.id} to={`/oauth-login/${oauthProvider.id}`}>
              {oauthProvider.name}
            </Link>
          ))
        }
      </div>
    </Centered>
  );
};

export default LoginPage;
