import React from 'react';
import { Navigate, useParams } from "react-router-dom";
import { useGetOauthProviderQuery } from '../../bold/gql';
import { stringify } from 'query-string';
import createHashGenerator from "@bold/hash-helper";
import { CircularProgress } from '@mui/material';
import { Centered } from '@guy/flex-flow';

const oauthStateGenerator = createHashGenerator({
  validator: (hash) => sessionStorage.getItem(`oauth-state-${hash}`) === null,
});

interface OauthLoginPageProps {
}

const OauthLoginPage: React.FC<OauthLoginPageProps> = () => {

  const params = useParams();
  const providerId = params.providerId;

  const { data, loading } = useGetOauthProviderQuery({
    variables: {
      id: providerId as string,
    },
    skip: providerId === undefined,
  });

  if (loading) {
    return <Centered>
      <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </Centered>;
  }

  const provider = data?.getOauthProvider;

  if (!provider) {
    return <Navigate to="/" />;
  }

  const oauthState = oauthStateGenerator.generate();
  sessionStorage.setItem(`oauth-state-${oauthState}`, provider.id);

  const queryString = stringify({
    client_id: provider.clientId,
    redirect_uri: provider.redirectUri,
    response_type: 'code',
    scope: provider.scope,
    state: oauthState,
  });

  window.location.href = `${provider.oauthUrl}/authorize?${queryString}`;

  return <>Redirecting...</>;
};

export default OauthLoginPage;
